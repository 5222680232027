<section class="large-footer-container-desktop" *ngIf="largeFooter">
  <div class="title-social-wrapper">
    <header class="large-footer-title">{{ largeFooter.title }}</header>
    <ng-container [ngTemplateOutlet]="socialIcons"></ng-container>
  </div>
  <div class="large-footer-items-container">
    <div class="large-footer-item" *ngFor="let item of largeFooter.components">
      <app-footer-dynamic-item
        *ngIf="item.__component === StrapiComponentTypeEnum.SubcategoryList"
        [item]="item"
      ></app-footer-dynamic-item>
      <app-footer-static-item
        *ngIf="item.__component === StrapiComponentTypeEnum.Paragraph"
        [item]="item"
      ></app-footer-static-item>
    </div>
  </div>
</section>

<section class="large-footer-container-mobile" *ngIf="largeFooter">
  <ng-container [ngTemplateOutlet]="socialIcons"></ng-container>
  <div class="large-footer-items-container">
    <div class="large-footer-row">
      <div
        class="large-footer-item"
        *ngFor="let item of largeFooter.components"
      >
        <app-footer-dynamic-item
          *ngIf="item.__component === StrapiComponentTypeEnum.SubcategoryList"
          [item]="item"
        ></app-footer-dynamic-item>
        <app-footer-static-item
          *ngIf="item.__component === StrapiComponentTypeEnum.Paragraph"
          [item]="item"
        ></app-footer-static-item>
      </div>
    </div>
  </div>
</section>

<ng-template #socialIcons>
  <div class="large-footer-social-container">
    <ul role="menubar">
      <li class="social-item" role="menuitem" aria-label="Instagram">
        <a
          href="https://www.instagram.com/pwnwydawnictwo/?hl=pl"
          title="Instagram"
          target="_blank"
        >
          <span class="fa-svg-icon svg-baseline" style="fill: #fff;">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
              />
            </svg>
          </span>
        </a>
      </li>
      <li class="social-item" role="menuitem" aria-label="Facebook">
        <a
          href="https://www.facebook.com/WydawnictwoPWN/"
          title="Facebook"
          target="_blank"
        >
          <span class="fa-svg-icon svg-baseline">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
              />
            </svg>
          </span>
        </a>
      </li>
      <li class="social-item" role="menuitem" aria-label="LinkedIn">
        <a
          href="https://www.linkedin.com/company/wydawnictwo-naukowe-pwn"
          title="Linkedin"
          target="_blank"
        >
          <span class="fa-svg-icon svg-baseline">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
          </span>
        </a>
      </li>
      <li class="social-item" role="menuitem" aria-label="Twitter">
        <a
          href="https://twitter.com/PWNwydawnictwo"
          title="Twitter"
          target="_blank"
        >
          <span class="fa-svg-icon svg-baseline">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              style="margin-top: 2px;"
            >
              <polygon
                class="cls-1"
                points="6.91 6.03 22.26 26.54 25.73 26.54 10.39 6.03 6.91 6.03"
              />
              <path
                class="cls-1"
                d="m29,0H3C1.34,0,0,1.34,0,3v26c0,1.66,1.34,3,3,3h26c1.66,0,3-1.34,3-3V3c0-1.66-1.34-3-3-3Zm-7.56,28.18l-6.98-9.34-8.65,9.34h-2.22l9.87-10.67L3.65,4.39h7.56l6.43,8.6,7.96-8.6h2.22l-9.19,9.93,10.36,13.86h-7.56Z"
              />
            </svg>
          </span>
        </a>
      </li>
      <li class="social-item" role="menuitem" aria-label="Youtube">
        <a
          href="https://www.youtube.com/channel/UCGbVVr5AxLYVeYJ20MeNZ-Q/featured"
          title="Youtube"
          target="_blank"
        >
          <span class="fa-svg-icon svg-baseline">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"
              />
            </svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
</ng-template>
