import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LargeFooterComponent } from '@app/large-footer/large-footer.component';
import { FooterStaticItemComponent } from '@app/large-footer/footer-static-item/footer-static-item.component';
import { FooterDynamicItemComponent } from '@app/large-footer/footer-dynamic-item/footer-dynamic-item.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LargeFooterComponent,
    FooterStaticItemComponent,
    FooterDynamicItemComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [LargeFooterComponent],
})
export class LargeFooterModule {}
