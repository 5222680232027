<div *ngIf="isBrowser()" class="bookstore-container">
  <div class="header-container">
    <h3>{{ component.title }}</h3>
    <div class="bookstore-logo">
      <a
        href="{{ component.bookstoreUrl }}"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="{{ logoSrc }}"
          alt="Logo księgarni"
          loading="lazy"
          (error)="onImageError()"
          width="208"
          height="52"
        />
        <!-- <img [strapiImgUrl]="logoSrc" [extension]="'.webp'" alt="Logo księgarni" loading="lazy" /> -->
      </a>
    </div>
    <div class="bookstore-description">
      <span>{{ component.shortDescription }}</span>
    </div>
    <div class="go-to-bookstore-container">
      <a
        href="{{ component.bookstoreUrl }}"
        class="go-to-bookstore"
        target="_blank"
        rel="noopener noreferrer"
      >
        {{ component.goToBookstoreLabel }}
        <span class="material-icons arrow-icon" role="presentation"
          >trending_flat</span
        >
      </a>
    </div>
  </div>
  <div class="promoted-books-container">
    <p-carousel
      [value]="books"
      [numVisible]="5"
      [numScroll]="1"
      [autoplayInterval]="3000"
      [responsiveOptions]="responsiveOptions"
      styleClass="p-carousel p-widget p-carousel-horizontal"
      *ngIf="initialized"
    >
      <!-- property styleClass is required to fix bug on build dev mode ...ECH!!! -->
      <ng-template let-book pTemplate="item">
        <div class="book-container">
          <div class="book">
            <a href="{{ book.link }}" target="_blank" rel="noopener noreferrer">
              <img
                [imgUrlWithSize]="imageFormat"
                [imgUrl]="book.images[0]?.url"
                alt="{{ book.title }}"
                loading="lazy"
              />
            </a>
            <div class="book-info">
              <a
                href="{{ book.link }}"
                class="title"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>{{ book.title }}</p>
              </a>
              <p class="authors">{{ booksAuthors.get(book.id) }}</p>
              <button
                class="bookstore-button"
                [style.background-color]="book.linkButtonBackgroundColor"
                (click)="navigateToLink(book.link)"
                [ariaLabel]="
                  book.linkButtonText + ' ' + book.title ||
                  'Zobacz więcej' + ' ' + book.title
                "
              >
                {{ book.linkButtonText || 'Zobacz więcej' }}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-carousel>
    <div class="button-container">
      <button [ariaLabel]="component.goToBookstoreLabel">
        <a
          href="{{ component.bookstoreUrl }}"
          target="_blank"
          rel="noopener noreferrer"
          >{{ component.goToBookstoreLabel }}</a
        >
      </button>
    </div>
  </div>
</div>
