import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  StrapiAboutPage,
  StrapiArticleListPage,
  StrapiArticlePage,
  StrapiAuthorListPage,
  StrapiAuthorPage,
  StrapiBecomeAuthorPage,
  StrapiBookListPage,
  StrapiContactPage,
  StrapiEventListPage,
  StrapiEventPage,
  StrapiJobOfferListPage,
  StrapiJobOfferPage,
  StrapiMainPage,
  StrapiPage,
  StrapiResultListPage,
  StrapiVideoListPage,
} from '@moose/pwn-cms-model/lib';
import { HttpClient } from '@angular/common/http';
import { StrapiSourceArticleListPage } from '@moose/pwn-cms-model/lib/model/strapi/single-types/strapi-source-article-list-page';
import { LocalstorageService } from '@shared/service/ssr/localstorage.service';
import { environment } from '@env/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

const redirectPathKey = 'redirectPath';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  mainPageTimeout = false;
  articlePageTimeout = false;
  searchResultListPageTimeout = false;
  articleListPageTimeout = false;
  eventPageTimeout = false;
  eventListPageTimeout = false;
  sourceArticleListPageTimeout = false;
  bookListPageTimeout = false;
  videoPageTimeout = false;
  videoListPageTimeout = false;
  scaleListPageTimeout = false;
  aboutPageTimeout = false;
  contactPageTimeout = false;
  private readonly tenantId = environment.tenantId;

  constructor(
    private http: HttpClient,
    private readonly localStorageService: LocalstorageService,
    private state: TransferState
  ) {}

  getMainPage(): Observable<StrapiMainPage> {
    type T = StrapiMainPage;
    const key = makeStateKey<T>('pageService_main');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.mainPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.mainPageTimeout = false;
        }, 2000);
        this.mainPageTimeout = true;
      }
      return of(value);
    }

    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/page/main`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }

  getArticlePage(): Observable<StrapiArticlePage> {
    type T = StrapiArticlePage;
    const key = makeStateKey<T>('pageService_articlePage');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.articlePageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.articlePageTimeout = false;
        }, 2000);
        this.articlePageTimeout = true;
      }
      return of(value);
    }

    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/page/article`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }

  getArticleListPage(): Observable<StrapiArticleListPage> {
    type T = StrapiArticleListPage;
    const key = makeStateKey<T>('pageService_alp');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.articleListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.articleListPageTimeout = false;
        }, 2000);
        this.articleListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/article-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getEventPage(): Observable<StrapiEventPage> {
    type T = StrapiEventPage;
    const key = makeStateKey<T>('pageService_ep');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.eventPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.eventPageTimeout = false;
        }, 2000);
        this.eventPageTimeout = true;
      }
      return of(value);
    }

    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/page/event`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }

  getEventListPage(): Observable<StrapiEventListPage> {
    type T = StrapiEventListPage;
    const key = makeStateKey<T>('pageService_elp');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.eventListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.eventListPageTimeout = false;
        }, 2000);
        this.eventListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/event-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getSourceArticleListPage(): Observable<StrapiSourceArticleListPage> {
    type T = StrapiSourceArticleListPage;
    const key = makeStateKey<T>('pageService_salp');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.sourceArticleListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.sourceArticleListPageTimeout = false;
        }, 2000);
        this.sourceArticleListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/source-article-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getVideoListPage(): Observable<StrapiVideoListPage> {
    type T = StrapiVideoListPage;
    const key = makeStateKey<T>('pageService_videop');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.videoListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.videoListPageTimeout = false;
        }, 2000);
        this.videoListPageTimeout = true;
      }
      return of(value);
    }
    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/video-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getVideoPage(): Observable<any> {
    const key = makeStateKey('pageService_videos');
    const value: any = this.state.get(key, null);
    if (value) {
      if (!this.videoPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.videoPageTimeout = false;
        }, 2000);
        this.videoPageTimeout = true;
      }
      return of(value);
    }
    return this.http
      .get<any>(`/strapi-proxy/${this.tenantId}/page/videos-page`)
      .pipe(
        map((x) => {
          this.state.set(key, x);
          return x;
        })
      );
  }

  getBookListPage(): Observable<StrapiBookListPage> {
    type T = StrapiBookListPage;
    const key = makeStateKey<T>('pageService_blp');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.bookListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.bookListPageTimeout = false;
        }, 2000);
        this.bookListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/book-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getScaleListPage(): Observable<StrapiBookListPage> {
    type T = StrapiBookListPage;
    const key = makeStateKey<T>('pageService_slp');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.scaleListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.scaleListPageTimeout = false;
        }, 2000);
        this.scaleListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/scale-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getAboutPage(): Observable<StrapiAboutPage> {
    type T = StrapiAboutPage;
    const key = makeStateKey<T>('pageService_about');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.aboutPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.aboutPageTimeout = false;
        }, 2000);
        this.aboutPageTimeout = true;
      }
      return of(value);
    }

    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/page/about`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }

  getContactPage(): Observable<StrapiContactPage> {
    type T = StrapiContactPage;
    const key = makeStateKey<T>('pageService_contact');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.contactPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.contactPageTimeout = false;
        }, 2000);
        this.contactPageTimeout = true;
      }
      return of(value);
    }

    return this.http.get<T>(`/strapi-proxy/${this.tenantId}/page/contact`).pipe(
      map((x) => {
        this.state.set<T>(key, x);
        return x;
      })
    );
  }

  getBecomeAuthorPage(): Observable<StrapiBecomeAuthorPage> {
    return this.http.get<StrapiBecomeAuthorPage>(
      `/strapi-proxy/${this.tenantId}/page/become-author`
    );
  }

  getResultListPage(): Observable<StrapiResultListPage> {
    type T = StrapiResultListPage;
    const key = makeStateKey<T>('pageService_rlpage');
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.searchResultListPageTimeout) {
        setTimeout(() => {
          this.state.set(key, null);
          this.searchResultListPageTimeout = false;
        }, 2000);
        this.searchResultListPageTimeout = true;
      }
      return of(value);
    }

    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/page/search-result-list`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }

  getPageById(id: string): Observable<StrapiPage> {
    return this.http.get<StrapiPage>(
      `/strapi-proxy/${this.tenantId}/page/${id}`
    );
  }

  getJobOfferPage(): Observable<StrapiJobOfferPage> {
    return this.http.get<StrapiJobOfferPage>(
      `/strapi-proxy/${this.tenantId}/page/job-offer`
    );
  }

  getAuthorListPage(): Observable<StrapiAuthorListPage> {
    return this.http.get<StrapiAuthorListPage>(
      `/strapi-proxy/${this.tenantId}/page/author-list`
    );
  }

  getAuthorPage(): Observable<StrapiAuthorPage> {
    return this.http.get<StrapiAuthorPage>(
      `/strapi-proxy/${this.tenantId}/page/author`
    );
  }

  getExpertListPage(): Observable<StrapiAuthorListPage> {
    return this.http.get<StrapiAuthorListPage>(
      `/strapi-proxy/${this.tenantId}/page/expert-list`
    );
  }

  getJobOfferListPage(): Observable<StrapiJobOfferListPage> {
    return this.http.get<StrapiJobOfferListPage>(
      `/strapi-proxy/${this.tenantId}/page/job-offer-list`
    );
  }

  getRedirectPath() {
    return this.localStorageService.getItem(redirectPathKey);
  }

  setRedirectPath(path: string | null) {
    return this.localStorageService.setItem(redirectPathKey, path);
  }

  deleteRedirectPath() {
    return this.localStorageService.removeItem(redirectPathKey);
  }
}
