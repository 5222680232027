import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  SimpleArticle,
  StrapiGoodToKnowArticlesComponent,
  StrapiImageClass,
} from '@moose/pwn-cms-model/lib';
import { ArticleService } from '@shared/service/article/article.service';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { environment } from '@env/environment';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';

@Component({
  selector: 'app-good-to-know-list',
  templateUrl: './good-to-know-list.component.html',
  styleUrls: [
    './good-to-know-list.component.scss',
    './good-to-know-list.component.mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoodToKnowListComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  component: StrapiGoodToKnowArticlesComponent;

  subscription: Subscription;
  articleList: SimpleArticle[] = [];

  backgroundImage: string;

  constructor(
    public articleService: ArticleService,
    public categoryService: CategoryService,
    private configurationService: ConfigurationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.configurationService.getConfiguration().subscribe((config) => {
      if (config && config.backgroundImage) {
        this.backgroundImage = new StrapiImageClass(
          config.backgroundImage
        ).getPublicImageUrl(
          this.getServerUrl() + '/strapi-proxy/' + this.tenantId
        );
      }
    });
    this.subscription = this.articleService
      .getGoodToKnowArticles(
        this.component.tag.name,
        this.component.maxArticles,
        this.component.tag.id
      )
      .subscribe((list) => {
        this.articleList = list;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
