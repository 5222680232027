// L - LIGHTEN
// D - DARKEN
export enum ScssModifiedProperties {
  PRIMARY_FONT_COLOR_LIGHTEN_60 = 'primary_font_color_lighten_60',
  SECONDARY_BACKGROUND_COLOR_LIGHTEN_30 = 'secondary_background_color_lighten_30',
  QUATERNARY_FONT_COLOR_LIGHTEN_15 = 'quaternary_background_color_15',
  ERROR_COLOR_LIGHTEN_20 = 'error_color_lighten_20',
  ERROR_COLOR_LIGHTEN_30 = 'error_color_lighten_30',
  SUCCESS_COLOR_LIGHTEN_55 = 'success_color_lighten_55',

  PRIMARY_BACKGROUND_COLOR_DARKEN_5 = 'primary_background_color_darken_5',
  PRIMARY_BACKGROUND_COLOR_DARKEN_70 = 'primary_background_color_darken_70',

  SECONDARY_BACKGROUND_COLOR_RGB = 'secondary_background_color_rgb',
  QUATERNARY_FONT_COLOR_RGB = 'quaternary_font_color_rgb',
  MAIN_THEME_COLOR_RGB = 'main_theme_color_rgb',
  PRIMARY_BACKGROUND_COLOR_RGB = 'main_theme_color_lighten_30_rgb',
}
