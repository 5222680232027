<div class="content-container" *ngIf="isBrowser()">
  <div class="elements">
    <app-microservice
      *ngFor="let category of component.categories"
      [category]="category"
      [readMoreLabel]="component.readMoreLabel"
      [imageSize]="component?.imageSize"
    >
    </app-microservice>
  </div>
</div>
