import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotArticleListComponent } from '@app/hot-article-list/hot-article-list.component';
import { HotArticleComponent } from '@app/hot-article-list/hot-article/hot-article.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { HeaderModule } from '@app/header/header.module';

@NgModule({
  declarations: [HotArticleListComponent, HotArticleComponent],
  imports: [CommonModule, RouterModule, SharedModule, HeaderModule],
  exports: [HotArticleComponent, HotArticleListComponent],
})
export class HotArticleListModule {}
