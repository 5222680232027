import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import {
  PwnAgreements,
  StrapiAuthenticationConfiguration,
  StrapiImageClass,
  StrapiOccupationalGroupComponent,
  StrapiAreaOfInterestComponent,
  StrapiStaticPageLinkComponent,
  StrapiTheme,
} from '@moose/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { UserService } from '@shared/service/user/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PwzValidator } from '@app/@shared/validators/pwz-validator';
import { AgreementsService } from '@app/@shared/service/agreements/agreements.service';
import { AgreementControl } from '@app/@shared/model/agreements/agreement-control';
import { ToastService } from '@shared/service/toast/toast.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { Router } from '@angular/router';

interface Country {
  code: string;
  name: string;
}

@Component({
  selector: 'app-register-existing',
  templateUrl: './register-existing.component.html',
  styleUrls: [
    './register-existing.component.scss',
    './register-existing.component.mobile.scss',
  ],
})
export class RegisterExistingComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  registerForm: UntypedFormGroup;
  group: UntypedFormControl;
  areaOfInterest: UntypedFormControl;
  pwzNumber: UntypedFormControl;
  firstName: UntypedFormControl;
  lastName: UntypedFormControl;
  country: UntypedFormControl;

  mandatoryAgreements: AgreementControl[];
  optionalAgreements: AgreementControl[];

  logo: StrapiImageClass = null;
  logoSrcOrg: string;
  logoSrc: string;

  showForm = false;

  background: StrapiImageClass = null;
  backgroundSrc: string;
  links: StrapiStaticPageLinkComponent[];

  authConfig: StrapiAuthenticationConfiguration;
  theme: StrapiTheme;
  agreements: PwnAgreements;

  occupationalGroups: StrapiOccupationalGroupComponent[];
  areasOfInterest: StrapiAreaOfInterestComponent[];

  configurationSubscription: Subscription;
  registerSubscription: Subscription;

  creatingAccount = false;

  modalMessage = '';
  modalError = false;

  countries: Country[] = [];
  countriesMap: Map<string, Country> = new Map();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private configurationService: ConfigurationService,
    private userService: UserService,
    private agreementsService: AgreementsService,
    public modal: NgbModal,
    private el: ElementRef,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  countryFormatter = (country: Country) => country.name;

  countrySearch = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      map((term) =>
        this.countries
          .filter((country) => new RegExp(term, 'mi').test(country.name))
          .slice(0, 10)
      )
    );

  ngOnInit(): void {
    console.log('init ');

    this.configurationSubscription = combineLatest([
      this.configurationService.getConfiguration(),
      this.configurationService.getAuthenticationConfiguration(),
      this.configurationService.getTheme(),
      this.agreementsService.getAgreements(),
      this.userService.getCountryCodeMap(),
    ])
      .pipe(
        map(([configuration, authConfig, theme, agreements, codeMap]) => ({
          configuration,
          authConfig,
          theme,
          agreements,
          codeMap,
        }))
      )
      .subscribe((result) => {
        if (
          result.configuration === null ||
          result.configuration === undefined ||
          result.authConfig === null ||
          result.authConfig === undefined ||
          result.theme === null ||
          result.theme === undefined ||
          result.agreements === null ||
          result.agreements === undefined ||
          result.codeMap === null ||
          result.codeMap === undefined
        ) {
          return;
        }
        console.log(result);
        // this.countries = [];

        result.codeMap.forEach((value, key) => {
          const entry = { code: key, name: value };
          this.countries.push(entry);
          this.countriesMap.set(key, entry);
        });
        this.agreements = result.agreements;

        this.authConfig = result.authConfig;
        this.theme = result.theme;

        this.logo = new StrapiImageClass(result.configuration.logo);
        this.logoSrcOrg = this.logo.getPublicImageUrl(
          this.getServerUrl() + '/strapi-proxy/' + this.tenantId
        );
        this.logoSrc = this.logoSrcOrg.replace(/\..[^.]+$/gi, '.webp');
        this.background = new StrapiImageClass(
          result.configuration.backgroundImage
        );
        this.backgroundSrc = `url("${this.background.getPublicImageUrl(
          this.getServerUrl() + '/strapi-proxy/' + this.tenantId
        )}")`;

        this.links = result.authConfig.links;

        this.occupationalGroups = result.authConfig.occupationalGroups;
        this.areasOfInterest = result.authConfig.areasOfInterest;

        this.createFormControls();
        this.createForm();
        this.showForm = true;
      });
  }

  ngOnDestroy(): void {
    this.configurationSubscription?.unsubscribe();
    this.registerSubscription?.unsubscribe();
  }

  createFormControls() {
    this.group = new UntypedFormControl(
      '',
      this.occupationalGroups.length ? [Validators.required] : []
    );
    this.areaOfInterest = new UntypedFormControl(
      '',
      this.areasOfInterest.length ? [Validators.required] : []
    );
    this.pwzNumber = new UntypedFormControl('');
    this.firstName = new UntypedFormControl('');
    this.lastName = new UntypedFormControl('');
    this.country = new UntypedFormControl('', Validators.required);
    this.country.setValue(this.countriesMap.get('PL'));

    this.mandatoryAgreements = [];
    this.optionalAgreements = [];

    for (const agreement of this.authConfig.mandatoryRegistrationAgreements) {
      const ssoAgreement = this.agreements.agreements.find(
        (agr) => agr.id === agreement.code
      );

      this.mandatoryAgreements.push({
        // control: new FormControl('', Validators.required),
        control: new UntypedFormControl(
          agreement.hidden ? true : '',
          Validators.required
        ),
        code: agreement.code,
        version: ssoAgreement.version,
        text: ssoAgreement.shortDescription,
        fullText: ssoAgreement.fullDescription,
        hidden: agreement.hidden,
      });
    }

    for (const agreement of this.authConfig.optionalRegistrationAgreements) {
      const ssoAgreement = this.agreements.agreements.find(
        (agr) => agr.id === agreement.code
      );

      this.optionalAgreements.push({
        control: new UntypedFormControl(''),
        code: agreement.code,
        version: ssoAgreement.version,
        text: ssoAgreement.shortDescription,
        fullText: ssoAgreement.fullDescription,
      });
    }
  }

  createForm() {
    this.registerForm = this.formBuilder.group(
      {
        group: this.group,
        areaOfInterest: this.areaOfInterest,
        pwzNumber: this.pwzNumber,
        firstName: this.firstName,
        lastName: this.lastName,
        country: this.country,
      },
      {
        validators: [PwzValidator.VerifyPwz()],
      }
    );

    for (const control of [
      ...this.mandatoryAgreements,
      ...this.optionalAgreements,
    ]) {
      this.registerForm.addControl(
        'agreement-' + control.code,
        control.control
      );
    }
  }

  setGroupValue(value: StrapiOccupationalGroupComponent) {
    if (value !== this.group.value) {
      this.group.setValue(value);
    }
  }

  setAreaOfInterestValue(value: StrapiAreaOfInterestComponent) {
    if (value !== this.areaOfInterest.value) {
      this.areaOfInterest.setValue(value);
    }
  }

  toggleVisible(event: MouseEvent) {
    const source = (event.target || event.srcElement) as HTMLElement;
    if (source.classList.contains('visible')) {
      source.classList.remove('visible');
      source.nextElementSibling.setAttribute('type', 'password');
    } else {
      source.classList.add('visible');
      source.nextElementSibling.setAttribute('type', 'text');
    }
  }

  async submitForm(modal: any) {
    if (this.registerForm.valid) {
      this.creatingAccount = true;
      this.registerSubscription?.unsubscribe();
      this.registerSubscription = this.userService
        .updateUserRegistrationData(
          this.registerForm.value,
          this.getSelectedAgreements()
        )
        .subscribe(
          (response) => {
            this.creatingAccount = false;
            this.authenticationService.credentials.fillRegistrationData = false;
            this.authenticationService.setCredentials(
              this.authenticationService.credentials
            );
            this.router.navigate(['/']);
          },
          (response) => {
            this.creatingAccount = false;

            const body = response.error;

            if (body.error) {
              let type = body.error.type;

              if (type === 'UnexpectedApiException') {
                type = body.error.reason.error.type;
              }

              switch (type) {
                default:
                  this.creatingAccount = false;
                  this.modalMessage =
                    'Wystąpił błąd podczas aktualizacji danych';
                  this.modalError = true;
                  this.modal.open(modal, {
                    centered: true,
                    windowClass: 'modal-window',
                  });
                  break;
              }
              this.scrollToFirstInvalidControl();
            }
          }
        );
    } else {
      for (const control in this.registerForm.controls) {
        if (this.registerForm.get(control).errors) {
          this.registerForm.get(control).markAsTouched();
        }
      }
      this.scrollToFirstInvalidControl();
    }
  }

  scrollToFirstInvalidControl() {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        'form .field-container .p-float-label input.ng-invalid'
      );

      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
      });
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  hasErrors(control: UntypedFormControl) {
    return control && control.errors && (control.dirty || control.touched);
  }

  showAgreement(modal: any, text: string) {
    this.modalMessage = text;
    this.modalError = false;
    this.modal.open(modal, { centered: true, windowClass: 'modal-window' });
  }

  displayToast(id: string, text: string): void {
    this.toastService.replaceToast(
      text,
      { classname: 'register-toast', delay: null },
      id
    );
  }

  removeToasts(): void {
    this.toastService.removeAllToasts();
  }

  logout() {
    this.authenticationService.logout();
  }

  private getSelectedAgreements(): PwnAgreements {
    return {
      agreements: this.getSelectedAgreementControls().map((agreement) => ({
        id: agreement.code,
        version: agreement.version,
        url: window.location.origin + '/zgoda/' + agreement.code,
      })),
    };
  }

  private getSelectedAgreementControls(): AgreementControl[] {
    return [
      ...this.mandatoryAgreements.filter((agr) => agr.control.value),
      ...this.optionalAgreements.filter((agr) => agr.control.value),
    ];
  }

  onLogoSrcError(): void {
    this.logoSrc = this.logoSrcOrg;
  }
}
