import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Image,
  SimpleArticle,
  StrapiImageSizeEnum,
} from '@moose/pwn-cms-model/lib';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { tap } from 'rxjs/operators';

import { CategoryService } from '@app/@shared/service/category/category.service';
import { environment } from '@env/environment';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { UserProductService } from '@shared/service/user-product/user-product.service';

@Component({
  selector: 'app-good-to-know',
  templateUrl: './good-to-know.component.html',
  styleUrls: [
    './good-to-know.component.scss',
    './good-to-know.component.mobile.scss',
  ],
})
export class GoodToKnowComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  article: SimpleArticle;

  @Input()
  readMore: string;

  @Input()
  imageSize: string;

  slugIdMap = new Map();

  categoriesSub: Subscription;
  isLoggedIn$: Observable<boolean>;
  public resize$: Subscription;
  userFavoriteArticleIds$: Subscription;

  userFavoriteArticlesIds: string[] = [];
  public imageDesktopFormat = StrapiImageSizeEnum.thumbnail;
  public imageMobileFormat = StrapiImageSizeEnum.small;
  public isMobile: boolean;

  constructor(
    public categoryService: CategoryService,
    private authenticationService: AuthenticationService,
    private userProductService: UserProductService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });

    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticlesIds = value;
      }
    );

    // Switch template to desktop <-> mobile
    this.resize$ = this.onWindowBreakPoints([
      '(max-width: 1000px)',
    ]).subscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  isFavorite(articleId: string): boolean {
    if (this.userFavoriteArticlesIds) {
      const index = this.userFavoriteArticlesIds.findIndex(
        (favoriteId) => favoriteId === articleId
      );
      return index > -1;
    } else {
      return false;
    }
  }

  addToFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .addArticleToFavorite(articleId)
        .subscribe((res) => {
          if (!this.userFavoriteArticlesIds) {
            this.userFavoriteArticlesIds = [];
          }
          if (
            res &&
            !this.userFavoriteArticlesIds.find(
              (favoriteId) => favoriteId === articleId
            )
          ) {
            this.userFavoriteArticlesIds.push(articleId);
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
        });
    }
  }

  removeFromFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .removeArticleFromFavorite(articleId)
        .subscribe((res) => {
          if (res && this.userFavoriteArticlesIds) {
            const productIndex = this.userFavoriteArticlesIds.indexOf(
              articleId
            );
            if (productIndex > -1) {
              this.userFavoriteArticlesIds.splice(productIndex, 1);
            }
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
        });
    }
  }

  onWindowBreakPoints(breakPoint: string[]): Observable<BreakpointState> {
    return this.breakpointObserver
      .observe(breakPoint)
      .pipe(tap((res) => (this.isMobile = res.matches)));
  }

  setImageAlt() {
    return this.article.img &&
      this.article.img.alternativeText &&
      this.article.img.alternativeText.length
      ? this.article.img.alternativeText
      : this.article.title;
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
    this.resize$?.unsubscribe();
  }
}
