import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CategoryService } from '@app/@shared/service/category/category.service';
import {
  StrapiComponent,
  StrapiSubCategoryListComponent,
} from '@moose/pwn-cms-model/lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer-dynamic-item',
  templateUrl: './footer-dynamic-item.component.html',
  styleUrls: [
    './footer-dynamic-item.component.scss',
    './footer-dynamic-item.component.mobile.scss',
  ],
})
export class FooterDynamicItemComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line
  public static readonly DEFAULT_DISPLAY_ALL_LABEL = 'Wyświetl wszystkie';

  _item: StrapiSubCategoryListComponent;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }

  @Input() set item(value: StrapiComponent) {
    this._item = value as StrapiSubCategoryListComponent;
  }

  getDisplayAllLabel() {
    const itemDisplayAllLabel = this._item.displayAllLabel;
    if (itemDisplayAllLabel) {
      return itemDisplayAllLabel;
    } else {
      return FooterDynamicItemComponent.DEFAULT_DISPLAY_ALL_LABEL;
    }
  }
}
