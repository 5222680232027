import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { SimpleArticle, StrapiCategory } from '@moose/pwn-cms-model/lib';
import { ArticleService } from '@shared/service/article/article.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-article-category',
  templateUrl: './top-article-category.component.html',
  styleUrls: [
    './top-article-category.component.scss',
    './top-article-category.component.mobile.scss',
  ],
})
export class TopArticleCategoryComponent implements OnInit, OnDestroy {
  @Input()
  category: StrapiCategory;

  @Input()
  showDates: boolean;

  @Input()
  articles: SimpleArticle[];

  slugIdMap = new Map();

  categoriesSub: Subscription;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }
}
