import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  StrapiImageClass,
  StrapiImageSizeEnum,
  StrapiParagraphComponent,
} from '@moose/pwn-cms-model/lib';

import { ParagraphImage } from '@shared/model/paragraph/paragraph-image';
import { environment } from '@env/environment';

@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: [
    './paragraph.component.scss',
    './paragraph.component.mobile.scss',
  ],
})
export class ParagraphComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  _component: StrapiParagraphComponent;

  @Input()
  set component(component: StrapiParagraphComponent) {
    this._component = component;

    this.background = new StrapiImageClass(component.backgroundImage);
    this.backgroundSrc = this.background.getPublicImageUrl(
      environment.serverUrl + '/strapi-proxy/' + this.tenantId
    );
    this.paragraphImage.link = this._component.imageInternalLink
      ? this._component.imageInternalLink
      : this._component.imageExternalLink;
    this.paragraphImage.isExternal = !!this._component.imageExternalLink;

    if (component.content) {
      this.showContent = true;
      this.content = this.sanitizer.bypassSecurityTrustHtml(
        this._component?.content
      );
    }
  }

  content: SafeHtml;
  showContent = false;

  paragraphImage = new ParagraphImage();

  background: StrapiImageClass;
  backgroundSrc: string;
  public imageDesktopFormat = StrapiImageSizeEnum.small;
  public imageMobileFormat = StrapiImageSizeEnum.small;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  getServerUrl(): string {
    return environment.serverUrl;
  }
}
