<div class="article-container">
  <div class="image-container">
    <a [routerLink]="['/artykul', article.slugId]">
      <img
        *ngIf="article.img.url"
        [imgUrlWithSize]="imageFormat"
        [imgUrl]="article.img.url"
        class="article-image"
        [ngClass]="imageSize"
        width="500"
        height="333"
        [alt]="setImageAlt()"
        [attr.loading]="requirePreload ? 'eager' : 'lazy'"
        [attr.fetchPriority]="requirePreload ? 'high' : 'low'"
      />
    </a>
  </div>
  <div class="header">
    <div class="category">
      <span *ngFor="let category of article.categories">
        <a
          [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
          class="category-name"
          [ngStyle]="{ color: category.color || 'black' }"
        >
          {{ category.name | uppercase }}
        </a>
      </span>
    </div>

    <button
      class="article-control-item"
      *ngIf="(isLoggedIn$ | async) && !isFavorite(article.id)"
      (click)="addToFavorite(article.id)"
    >
      <span class="material-icons favorite-icon-add">favorite_border</span>
    </button>
    <button
      class="article-control-item"
      *ngIf="(isLoggedIn$ | async) && isFavorite(article.id)"
      (click)="removeFromFavorite(article.id)"
    >
      <span class="material-icons favorite-icon-remove">favorite</span>
    </button>
  </div>

  <div class="article-title">
    <a
      class="article-link"
      [routerLink]="['/artykul', article.slugId]"
      [innerHtml]="article.title"
    ></a>
  </div>
  <div class="author-and-date">
    <span class="author-date col-8">{{
      article.date | date: 'dd.MM.yyyy'
    }}</span>
    <a
      class="read-label col-4"
      [routerLink]="['/artykuly', slugIdMap.get(article.slugId)]"
    >
      <span [id]="'hotarticle-cmp-read-label' + article.slugId">{{
        readLabel
      }}</span>
      <i
        role="presentation"
        class="material-icons"
        aria-labelledby="hotarticle-cmp-read-label"
        >trending_flat</i
      >
    </a>
  </div>
</div>
