import { Component, OnInit, Input } from '@angular/core';
import { ArticleService } from '@shared/service/article/article.service';
import { StrapiPromotedBooksComponent } from '@moose/pwn-cms-model/lib';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';

@Component({
  selector: 'app-promoted-book-list',
  templateUrl: './promoted-book-list.component.html',
  styleUrls: [
    './promoted-book-list.component.scss',
    './promoted-book-list.component.mobile.scss',
  ],
})
export class PromotedBookListComponent implements OnInit {
  @Input()
  component: StrapiPromotedBooksComponent;

  constructor(public partnerService: ArticleService) {}

  ngOnInit(): void {}

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
