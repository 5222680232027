import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@app/@shared';
import { ParagraphComponent } from '@app/paragraph/paragraph.component';

@NgModule({
  declarations: [ParagraphComponent],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [ParagraphComponent],
})
export class ParagraphModule {}
