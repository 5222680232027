import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PwnAgreements, StrapiConfiguration } from '@moose/pwn-cms-model/lib';
import { ConfigurationService } from '../configuration/configuration.service';
import { mergeMap, timeout } from 'rxjs/operators';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { PlatformService } from '../ssr/platform.service';

const AGREEMENTS_KEY = makeStateKey<StrapiConfiguration>(
  'agreementsService_agreements'
);

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  agreementsSubject = new BehaviorSubject(null);
  agreementsInitialized = false;

  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService,
    private state: TransferState
  ) {}

  getAgreements(): Observable<PwnAgreements> {
    return this.configurationService.getConfiguration().pipe(
      mergeMap((config) => {
        if (!config) {
          return EMPTY;
        }

        if (!this.agreementsInitialized) {
          this.loadAgreements(config);
          this.agreementsInitialized = true;
        }
        return this.agreementsSubject;
      })
    );
  }

  loadAgreements(config: StrapiConfiguration) {
    const agreements = this.state.get<StrapiConfiguration>(
      AGREEMENTS_KEY,
      null
    );
    if (!agreements && PlatformService.isBrowser) {
      this.http
        .get<StrapiConfiguration>('/pwn-user/agreements/' + config.tenantId)
        .pipe(timeout(4000))
        .subscribe(
          (conf) => {
            this.agreementsSubject.next(conf);
            this.state.set<StrapiConfiguration>(AGREEMENTS_KEY, conf);
          },
          (error) => {
            console.log('agreements error: ');
            console.log(error);
          }
        );
    } else {
      this.agreementsSubject.next(agreements);
      this.state.set(AGREEMENTS_KEY, null);
    }
  }
}
