import { Component, Input, OnInit } from '@angular/core';
import { StrapiAttachmentComponent } from '@moose/pwn-cms-model/lib/model/strapi/components/strapi-attachment-component';

import { environment } from '@env/environment';
import { UrlService } from '@app/@shared/service/url/url.service';

// import { StrapiImage, StrapiImageClass } from '@moose/pwn-cms-model/lib';
// import { saveAs } from 'file-saver';
// import { AttachmentsService } from '@shared/service/attachments/attachments.service';

@Component({
  selector: 'app-article-attachment',
  templateUrl: './article-attachment.component.html',
  styleUrls: [
    './article-attachment.component.scss',
    './article-attachment.component.mobile.scss',
  ],
})
export class ArticleAttachmentComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  @Input()
  component: StrapiAttachmentComponent;

  attachmentUrl: string;
  fileIconUrl: string;

  constructor(private urlService: UrlService) {}

  ngOnInit(): void {
    this.attachmentUrl = this.urlService.getImageUrl(this.component.file.url);
    this.fileIconUrl = this.chooseIcon();
  }

  // download(url: string, name: string) {
  //   this.downloads.download(url).subscribe((blob) => saveAs(blob, name));
  // }

  chooseIcon() {
    switch (this.component.file.ext) {
      case '.jpg':
      case '.png':
      case '.jpeg':
      case '.gif':
      case '.raw':
      case '.swf':
      case '.svg':
        return 'assets/attachments/img.png';
      case '.avi':
      case '.asf':
      case '.3gp':
      case '.dvd':
      case '.mov':
      case '.mpeg4':
      case '.mp4':
      case '.wmv':
        return 'assets/attachments/video.png';
      case '.pdf':
        return 'assets/attachments/pdf.png';
      case '.xlsx':
        return 'assets/attachments/xlsx.png';
      case '.doc':
      case '.docx':
        return 'assets/attachments/doc.png';
      case '.ppt':
      case '.pptx':
        return 'assets/attachments/ppt.png';
      case '.txt':
        return 'assets/attachments/txt.png';
      default:
        return 'assets/attachments/file.png';
    }
  }
}
