import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopArticleListComponent } from './top-article-list.component';
import { TopArticleCategoryComponent } from './top-article-category/top-article-category.component';
import { TopArticleComponent } from './top-article/top-article.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    TopArticleListComponent,
    TopArticleCategoryComponent,
    TopArticleComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, HeaderModule],
  exports: [TopArticleListComponent, TopArticleComponent],
})
export class TopArticleListModule {}
