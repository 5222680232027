import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '@shared/service/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: { '[class.ngb-toasts]': 'true' },
})
export class ToastContainerComponent implements OnInit {
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {}

  isTemplate(toast: any): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }

  close(toast: any): void {
    this.toastService.remove(toast);
  }
}
