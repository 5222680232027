import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsletterComponent } from '@app/newsletter/newsletter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SmallFooterModule } from '@app/shell/small-footer/small-footer.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AdVideoComponent } from './ad-video.component';

@NgModule({
  declarations: [AdVideoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SmallFooterModule,
    CheckboxModule,
    RouterModule,
    InputTextModule,
    TooltipModule,
    NgbTooltipModule,
  ],
  exports: [AdVideoComponent],
})
export class AdVideoModule {}
