import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StrapiCategory,
  StrapiImage,
  StrapiImageClass,
  StrapiImageSizeEnum,
} from '@moose/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-microservice',
  templateUrl: './microservice.component.html',
  styleUrls: [
    './microservice.component.scss',
    './microservice.component.mobile.scss',
  ],
})
export class MicroserviceComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  category: StrapiCategory;

  @Input()
  readMoreLabel: string;

  @Input()
  imageSize: string;

  imageDesktopFormat = StrapiImageSizeEnum.medium;
  imageMobileFormat = StrapiImageSizeEnum.medium;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  defaultColor = '#ffaa22';

  imageSrc: string;

  mobileImageSrc: string;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    if (this.category.image != null) {
      const bookImage = new StrapiImageClass(this.category.image);
      this.imageSrc = bookImage.getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }

    if (this.category.mobileImage != null) {
      const categoryImage = new StrapiImageClass(this.category.mobileImage);
      this.mobileImageSrc = categoryImage.getPublicImageUrl(
        environment.serverUrl + '/strapi-proxy/' + this.tenantId
      );
    }

    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }

  getImageAlt(imageObj: StrapiImage): string {
    if (imageObj.alternativeText && imageObj.alternativeText.length) {
      return imageObj.alternativeText;
    }
    return 'Obrazek mikroserwisu';
  }
}
