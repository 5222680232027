<ng-container
  *ngFor="let component of components"
  [ngSwitch]="component.__component"
>
  <app-ad-video
    *ngSwitchCase="componentTypes.AdVideo"
    [component]="component"
  ></app-ad-video>
</ng-container>
<div *ngFor="let component of components" [ngSwitch]="component.__component">
  <app-ad
    *ngSwitchCase="componentTypes.Ad"
    [component]="component"
    [categories]="article?.categories"
  ></app-ad>
  <app-static-text
    *ngSwitchCase="componentTypes.StaticText"
    [component]="component"
  ></app-static-text>
  <app-newsletter
    *ngSwitchCase="componentTypes.Newsletter"
    [component]="component"
    [style]="'small'"
  ></app-newsletter>
  <app-custom-html
    *ngSwitchCase="componentTypes.CustomHtml"
    [component]="component"
  ></app-custom-html>
  <app-read-also-list
    *ngSwitchCase="componentTypes.ReadAlso"
    [component]="component"
    [relatedArticles]="article?.relatedArticles"
  ></app-read-also-list>
</div>
