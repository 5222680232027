import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private readonly tenantId = environment.tenantId;
  constructor(private http: HttpClient) {}

  createPdf(articleId: string): Observable<ArrayBuffer> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(`/pwn-pdf/create/${this.tenantId}/${articleId}`, {
      headers,
      responseType: 'arraybuffer',
    });
  }
}
