import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PwnUser, PwnUserWithTokens } from '@moose/pwn-cms-model/lib';
import { BehaviorSubject, NEVER, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocalstorageService } from '@shared/service/ssr/localstorage.service';

export interface Credentials {
  email: string;
  tenantId: string;
  accessToken: string;
  refreshToken: string;
  fillRegistrationData?: boolean;
}

const credentialsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private _credentials: Credentials | null = null;

  private isLoggedIn = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.isLoggedIn.asObservable();
  public isLoggedOut$: Observable<boolean> = this.isLoggedIn$.pipe(
    map((isLoggedIn) => !isLoggedIn)
  );

  private pwnUserWithTokens = new BehaviorSubject<PwnUserWithTokens>(null);
  public pwnUserWithTokens$: Observable<
    PwnUserWithTokens
  > = this.pwnUserWithTokens.asObservable();

  private userSubject = new BehaviorSubject<PwnUser>(null);
  public user$ = this.userSubject.asObservable();
  public redirectUrl: string;
  public isLogoutWithRedirect: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private readonly localStorageService: LocalstorageService
  ) {
    const savedCredentials = this.localStorageService.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this.isLoggedIn.next(true);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  refreshToken(): Observable<PwnUserWithTokens> {
    if (this.credentials && this.credentials.refreshToken) {
      return this.http
        .post<any>('/pwn-user/login/refresh', {
          refreshToken: this.credentials.refreshToken,
        })
        .pipe(
          tap(
            (pwnUserWithTokens: PwnUserWithTokens) => {
              this.setCredentials({
                email: pwnUserWithTokens.user.email,
                tenantId: pwnUserWithTokens.user.tenantId,
                accessToken: pwnUserWithTokens.tokens.accessToken,
                refreshToken: pwnUserWithTokens.tokens.refreshToken,
                fillRegistrationData:
                  pwnUserWithTokens.user.fillRegistrationData,
              });
              this.updatePwnUserWithTokensSubject(pwnUserWithTokens);
            },
            (error) => {
              this.logout();
            }
          )
        );
    } else {
      return NEVER;
    }
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * @param credentials The user credentials.
   */
  setCredentials(credentials?: Credentials) {
    this._credentials = credentials || null;
    if (credentials) {
      this.localStorageService.setItem(
        credentialsKey,
        JSON.stringify(credentials)
      );
      this.isLoggedIn.next(true);
    } else {
      this.localStorageService.removeItem(credentialsKey);
      this.isLoggedIn.next(false);
    }
  }

  logout() {
    this.setCredentials(null);
    this.updateUserSubject(null);
    this.updatePwnUserWithTokensSubject(null);
    if (this.isLogoutWithRedirect) {
      this.router.navigate(['/']);
      this.setIsLogoutRedirect(false);
    }
  }

  updateUserSubject(user: PwnUser): void {
    this.userSubject.next(user);
  }

  updatePwnUserWithTokensSubject(pwnUserWithTokens: PwnUserWithTokens): void {
    this.pwnUserWithTokens.next(pwnUserWithTokens);
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  getRedirectUrl(): string {
    return this.redirectUrl;
  }

  setIsLogoutRedirect(isLogoutWithRedirect: boolean): void {
    this.isLogoutWithRedirect = isLogoutWithRedirect;
  }

  getIsLogoutRedirect(): boolean {
    return this.isLogoutWithRedirect;
  }
}
