import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PromotedBookListComponent } from '@app/promoted-book-list/promoted-book-list.component';
import { PromotedBookComponent } from '@app/promoted-book-list/promoted-book/promoted-book.component';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [PromotedBookListComponent, PromotedBookComponent],
  imports: [CommonModule, SharedModule],
  exports: [PromotedBookComponent, PromotedBookListComponent],
})
export class PromotedBookListModule {}
