import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from '@angular/core';
import { UrlService } from '../service/url/url.service';
import { StrapiImage, StrapiImageSizeEnum } from '@moose/pwn-cms-model/lib';

@Directive({
  selector: '[strapiImageGood]',
})
export class StrapiImageGoodDirective implements OnChanges {
  @Input() strapiImageGood: StrapiImage;
  @Input() strapiImageDesiredFormat: StrapiImageSizeEnum | undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private urlService: UrlService
  ) {}

  ngOnChanges(): void {
    const defaultAlt = this.el.nativeElement.getAttribute('alt');
    if (this.strapiImageGood) {
      this.renderer.setAttribute(
        this.el.nativeElement,
        'src',
        this.getImageUrl()
      );
      this.renderer.setAttribute(
        this.el.nativeElement,
        'alt',
        this.strapiImageGood.alternativeText?.length > 0
          ? this.strapiImageGood.alternativeText
          : defaultAlt
      );
    }
  }

  private getImageUrl(): string {
    const { url } = this.strapiImageGood;

    if (this.strapiImageDesiredFormat) {
      const [
        modifiedImageurl,
        originalImageUrl,
      ] = this.urlService.getImageUrlWithSize(
        url,
        this.strapiImageDesiredFormat
      );
      if (!this.strapiImageGood.formats) {
        return originalImageUrl;
      }
      if (this.strapiImageGood.formats[this.strapiImageDesiredFormat]) {
        return modifiedImageurl;
      }
      return originalImageUrl;
    }

    return this.urlService.getImageUrl(url);
  }
}
