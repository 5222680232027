import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from '@app/shell/main-menu/main-menu.component';
import { CategoryDetailsComponent } from '@app/shell/main-menu/category-details/category-details.component';
import { CategoryPopupComponent } from '@app/shell/main-menu/category-popup/category-popup.component';
import { MainMenuEntryComponent } from '@app/shell/main-menu/main-menu-entry/main-menu-entry.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { SmallFooterModule } from '@app/shell/small-footer/small-footer.module';
import { GlobalSearchBarComponent } from './global-search-bar/global-search-bar.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [
    MainMenuComponent,
    CategoryDetailsComponent,
    CategoryPopupComponent,
    MainMenuEntryComponent,
    BreadcrumbComponent,
    GlobalSearchBarComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    SmallFooterModule,
    FormsModule,
    SharedModule,
  ],
  exports: [MainMenuComponent, BreadcrumbComponent],
})
export class MainMenuModule {}
