<div class="data-container row">
  <a
    *ngIf="_article"
    [routerLink]="['/artykuly', slugIdMap.get(_article.categories[0].id)]"
    class="category-name col-8"
    [ngStyle]="{ color: _article.categories[0].color }"
  >
    {{ _article.categories[0].name }}
  </a>
  <span class="date col-4" *ngIf="_article && showDates">{{
    _article.date | date: 'dd.MM.yyyy'
  }}</span>
</div>
<h2 *ngIf="_article">
  <a
    [routerLink]="['/artykul', _article.slugId]"
    [innerHtml]="_article.title"
  ></a>
  <a [routerLink]="['/artykul', _article.slugId]" class="read-more">
    Czytaj
    <span role="presentation" class="material-icons arrow-icon"
      >trending_flat</span
    >
  </a>
</h2>
