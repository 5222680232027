import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ScssModifiedProperties } from '@shared/util/scss-modified-properties';
import { ScssProperties } from '@shared/util/scss-properties';

export const PREFIX = '--';

@Component({
  selector: 'app-sass-helper',
  templateUrl: './sass-helper.component.html',
  styleUrls: ['./sass-helper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SassHelperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  readProperty(name: string): string {
    const bodyStyles = window.getComputedStyle(document.body);
    return bodyStyles.getPropertyValue(PREFIX + name).replace(' ', '');
  }

  setProperty(name: string, value: string): void {
    if (typeof document !== 'undefined' && document != null) {
      document.body.style.setProperty(PREFIX + name, value);
    }
  }

  setLighterOrDarkerProperty(
    name: string,
    value: string,
    percent: number
  ): void {
    if (typeof document !== 'undefined' && document != null) {
      document.body.style.setProperty(
        PREFIX + name,
        this.makeLighterOrDarker(value, percent)
      );
    }
  }

  convertHexToRgb(name: string, value: string): void {
    if (typeof document !== 'undefined' && document != null) {
      document.body.style.setProperty(PREFIX + name, this.hexToRgb(value));
    }
  }

  makeLighterOrDarker(hex: string, percent: number): string {
    const hexToNum = parseInt(hex.replace('#', ''), 16);
    const amt = Math.round(2.55 * percent);
    const R = (hexToNum >> 16) + amt;
    const B = ((hexToNum >> 8) & 0x00ff) + amt;
    const G = (hexToNum & 0x0000ff) + amt;
    return (
      '#' +
      (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
      )
        .toString(16)
        .slice(1)
    );
  }

  hexToRgb(hex: string): string {
    const R = parseInt(hex.substring(1, 3), 16);
    const G = parseInt(hex.substring(3, 5), 16);
    const B = parseInt(hex.substring(5, 7), 16);
    return `${R}, ${G}, ${B}`;
  }

  setModifiedProperties(colorMap: Map<string, string>): void {
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.PRIMARY_FONT_COLOR_LIGHTEN_60,
      colorMap.get(ScssProperties.PRIMARY_FONT_COLOR) ?? '#000000',
      60
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.ERROR_COLOR_LIGHTEN_20,
      colorMap.get(ScssProperties.ERROR_COLOR) ?? '#f35f5f',
      20
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.ERROR_COLOR_LIGHTEN_30,
      colorMap.get(ScssProperties.ERROR_COLOR) ?? '#f35f5f',
      30
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.SUCCESS_COLOR_LIGHTEN_55,
      colorMap.get(ScssProperties.SUCCESS_COLOR) ?? '#f35f5f',
      55
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.SECONDARY_BACKGROUND_COLOR_LIGHTEN_30,
      colorMap.get(ScssProperties.SECONDARY_BACKGROUND_COLOR) ?? '#26509b',
      30
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.QUATERNARY_FONT_COLOR_LIGHTEN_15,
      colorMap.get(ScssProperties.QUATERNARY_FONT_COLOR) ?? '#868686',
      15
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.PRIMARY_BACKGROUND_COLOR_DARKEN_5,
      colorMap.get(ScssProperties.PRIMARY_BACKGROUND_COLOR) ?? '#ffffff',
      -5
    );
    this.setLighterOrDarkerProperty(
      ScssModifiedProperties.PRIMARY_BACKGROUND_COLOR_DARKEN_70,
      colorMap.get(ScssProperties.PRIMARY_BACKGROUND_COLOR) ?? '#ffffff',
      -70
    );
    this.convertHexToRgb(
      ScssModifiedProperties.SECONDARY_BACKGROUND_COLOR_RGB,
      colorMap.get(ScssProperties.SECONDARY_BACKGROUND_COLOR) ?? '#26509b'
    );
    this.convertHexToRgb(
      ScssModifiedProperties.QUATERNARY_FONT_COLOR_RGB,
      colorMap.get(ScssProperties.QUATERNARY_FONT_COLOR) ?? '#868686'
    );
    this.convertHexToRgb(
      ScssModifiedProperties.MAIN_THEME_COLOR_RGB,
      colorMap.get(ScssProperties.MAIN_THEME_COLOR) ?? '#26509b'
    );
    this.convertHexToRgb(
      ScssModifiedProperties.PRIMARY_BACKGROUND_COLOR_RGB,
      colorMap.get(ScssProperties.PRIMARY_BACKGROUND_COLOR) ?? '#ffffff'
    );
  }
}
