<div *ngIf="isTiles()">
  <div
    *ngIf="visible"
    [ngClass]="{
      categoryPopup: true
    }"
    @showHideTrigger
  >
    <div
      class="categoryPopupContainer"
      *ngFor="let chunk of childCategoriesChunks"
    >
      <app-category-details
        *ngFor="let categoryId of chunk"
        [categoryId]="categoryId"
        [mainMenuState]="mainMenuState"
      ></app-category-details>
    </div>
  </div>
</div>
