<p
  (mouseover)="onMenuClicked()"
  [ngClass]="{
    selected: isSelected()
  }"
  [routerLinkActive]="['active']"
>
  <a
    *ngIf="link.type === componentTypes.CategoryLink"
    [routerLink]="['/artykuly', slugIdMap.get(link.targetId)]"
  >
    {{ link.displayName | uppercase }}
  </a>
  <a
    *ngIf="
      link.type === componentTypes.StaticPageLink && link.external && !link.page
    "
    href="{{ link.targetId }}"
    target="_blank"
    rel="noopener noreferrer nofollow"
  >
    {{ link.displayName | uppercase }}
  </a>
  <a
    *ngIf="
      link.type === componentTypes.StaticPageLink &&
      !link.external &&
      !link.page
    "
    [routerLink]="[link.targetId]"
  >
    {{ link.displayName | uppercase }}
  </a>
  <a
    *ngIf="
      link.type === componentTypes.StaticPageLink && !link.external && link.page
    "
  >
    <app-modal-popups
      [displayName]="link.displayName"
      [pageContent]="link.page?.content"
    ></app-modal-popups>
  </a>
</p>
