import { Component, Input, OnInit } from '@angular/core';
import { Author } from '@moose/pwn-cms-model/lib';

@Component({
  selector: 'app-article-author-info-list',
  templateUrl: './article-author-info-list.component.html',
  styleUrls: [
    './article-author-info-list.component.scss',
    './article-author-info-list.component.mobile.scss',
  ],
})
export class ArticleAuthorInfoListComponent implements OnInit {
  @Input()
  authors: Author[];

  constructor() {}

  ngOnInit(): void {}
}
