<div class="application-container">
  <app-register-existing
    *ngIf="showRegisterExistingForm()"
  ></app-register-existing>
  <app-main-menu *ngIf="!showRegisterExistingForm()"></app-main-menu>
  <div class="content" *ngIf="!showRegisterExistingForm()">
    <app-breadcrumb></app-breadcrumb>
    <div>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-small-footer *ngIf="!showRegisterExistingForm()"></app-small-footer>
</div>
