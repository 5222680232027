<div
  class="paragraph-background"
  [style]="{
    background: backgroundSrc ? 'url(' + backgroundSrc + ')' : '',
    'min-height':
      (_component.backgroundImage ? _component.backgroundImage.height : 0) +
      'px'
  }"
>
  <div class="paragraph-container">
    <h2 [innerHtml]="_component.title"></h2>
    <ng-container *ngIf="_component.image">
      <div *ngIf="paragraphImage.link">
        <a
          [href]="paragraphImage.link"
          target="{{ paragraphImage.isExternal ? '_blank' : '' }}"
          rel="{{
            paragraphImage.isExternal ? 'noopener noreferrer nofollow' : ''
          }}"
        >
          <ng-container *ngTemplateOutlet="imageContainer"></ng-container>
        </a>
      </div>
      <div *ngIf="!paragraphImage.link">
        <ng-container *ngTemplateOutlet="imageContainer"></ng-container>
      </div>
    </ng-container>
    <div class="paragraph-content">
      <p *ngIf="showContent" class="innerHTML" [innerHTML]="content"></p>
      <div class="paragraph-button-container" *ngIf="_component.buttonText">
        <a
          *ngIf="_component.buttonText && _component.buttonInternalUrl"
          class="paragraph-button"
          [routerLink]="_component.buttonInternalUrl"
          >{{ _component.buttonText }}</a
        >
        <a
          *ngIf="_component.buttonText && _component.buttonExternalUrl"
          class="paragraph-button"
          href="{{ _component.buttonExternalUrl }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          >{{ _component.buttonText }}</a
        >
      </div>
    </div>
  </div>
</div>

<ng-template #imageContainer>
  <img
    [ngClass]="{
      'image-desktop': true,
      'image-style-align-left': _component.imagePosition === 'left',
      'image-style-align-right': _component.imagePosition === 'right'
    }"
    [imgUrlWithSize]="imageDesktopFormat"
    [imgUrl]="_component.image.url"
    alt="{{ _component.image.alternativeText }}"
    loading="lazy"
  />
  <img
    *ngIf="_component.showImageOnMobile"
    class="image-mobile"
    [imgUrlWithSize]="imageMobileFormat"
    alt="{{ _component.image.alternativeText }}"
    [imgUrl]="_component.image.url"
    loading="lazy"
  />
</ng-template>
