<div *ngIf="!zoneLoaded" class="ad-loader"></div>
<div *ngIf="component && isBrowser() && (showDesktop || showMobile)">
  <div
    *ngIf="showDesktop"
    class="desktop ad-container"
    [style.text-align]="desktopAlign"
    [innerHtml]="desktopUrl"
  ></div>
  <div
    *ngIf="showMobile"
    class="mobile ad-container"
    [innerHtml]="mobileUrl"
  ></div>
</div>
