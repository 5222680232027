export enum ScssProperties {
  PRIMARY_FONT_COLOR = 'primary_font_color',
  SECONDARY_CONT_COLOR = 'secondary_font_color',
  THIRD_FONT_COLOR = 'tertiary_font_color',
  QUATERNARY_FONT_COLOR = 'quaternary_font_color',
  PRIMARY_BACKGROUND_COLOR = 'primary_background_color',
  SECONDARY_BACKGROUND_COLOR = 'secondary_background_color',
  TERTIARY_BACKGROUND_COLOR = 'tertiary_background_color',
  QUATERNARY_BACKGROUND_COLOR = 'quaternary_background_color',
  ERROR_COLOR = 'error_color',
  SUCCESS_COLOR = 'success_color',
  CONTROL_PRIMARY_COLOR = 'control_primary_color',
  MAIN_THEME_COLOR = 'main_theme_color',

  FACEBOOK_BLUE = 'facebook_blue',
  TWITTER_BLUE = 'twitter_blue',
  LINKEDIN_BLUE = 'linkedin_blue',

  PRIMARY_FONT = 'primary_font',
  SECONDARY_FONT = 'secondary_font',
}
