import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { HotArticleListModule } from '@app/hot-article-list/hot-article-list.module';
import { PromotedBookListModule } from '@app/promoted-book-list/promoted-book-list.module';
import { MicroserviceListModule } from '@app/microservice-list/microservice-list.module';
import { LargeFooterModule } from '@app/large-footer/large-footer.module';
import { PartnerListModule } from '@app/partner-list/partner-list.module';
import { TopArticleListModule } from '@app/top-article-list/top-article-list.module';
import { AdModule } from '@app/ad/ad.module';
import { BookstoreModule } from '@app/bookstore/bookstore.module';
import { GoodToKnowListModule } from '@app/good-to-know-list/good-to-know-list.module';
import { ImageGalleryModule } from '@app/image-gallery/image-gallery.module';
import { NewsletterModule } from '@app/newsletter/newsletter.module';
import { RouterModule, Routes } from '@angular/router';
import { AdVideoModule } from '@app/ad-video/ad-video.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    HomeRoutingModule,
    HotArticleListModule,
    AdModule,
    AdVideoModule,
    TopArticleListModule,
    PromotedBookListModule,
    PartnerListModule,
    LargeFooterModule,
    MicroserviceListModule,
    GoodToKnowListModule,
    BookstoreModule,
    ImageGalleryModule,
    NewsletterModule,
  ],
  declarations: [HomeComponent],
})
export class HomeModule {}
