<div *ngIf="category" @showHideTrigger class="menuCategoryDetails">
  <div
    [ngClass]="{
      left: true,
      selected: selected
    }"
    (mouseenter)="selected = true"
    (mouseleave)="selected = false"
  >
    <a
      [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
      (click)="closeMenu()"
    >
      <div [ngStyle]="{ background: category.color }" class="imageContainer">
        <img
          [imgUrlWithSize]="imageFormat"
          [imgUrl]="category.img"
          [alt]="getImageAlt(category)"
        />
      </div>
      <p class="category-name" [ngStyle]="{ 'box-shadow': getBoxShadow() }">
        {{ category.displayName | uppercase }}
      </p>
    </a>
  </div>
  <div *ngIf="childCategories && childCategories.length > 0" class="right">
    <p
      *ngFor="let cat of childCategories"
      (mouseover)="cat.selected = true"
      (mouseout)="cat.selected = false"
      [ngStyle]="
        cat.selected
          ? { 'box-shadow': '0px +3px ' + category.color }
          : { 'box-shadow': 'none' }
      "
    >
      <a
        [routerLink]="['/artykuly', slugIdMap.get(cat.id)]"
        (click)="closeMenu()"
      >
        {{ cat.displayName }}
      </a>
    </p>
    <br />
    <div class="bottom">
      <a
        [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
        (click)="closeMenu()"
      >
        <p [ngStyle]="{ color: category.color }">Inne specjalizacje &#10230;</p>
      </a>
    </div>
  </div>
</div>
