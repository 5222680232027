<div *ngIf="mainMenu" (mouseleave)="onMouseLeave()">
  <div
    class="topnav-container"
    [ngClass]="{
      alwaysVisible: mainMenu.alwaysVisible
    }"
  >
    <div class="topnav">
      <div class="topnav__logo">
        <a [routerLink]="['/']">
          <img
            class="logoImg"
            [strapiImgUrl]="
              favicon?.getPublicImageUrl(
                getServerUrl() + '/strapi-proxy/' + tenantId
              )
            "
            [extension]="'.webp'"
            alt="Psychologia | PWN"
            width="32"
            height="32"
          />
        </a>
      </div>
      <div class="categories-wrapper">
        <div *ngFor="let link of elements">
          <app-category-menu-entry
            [link]="link"
            [mainMenuState]="mainMenuState"
          ></app-category-menu-entry>
        </div>
      </div>
      <!-- <app-breadcrumb></app-breadcrumb> -->
      <span class="menu search">
        <a class="search-icon" [routerLink]="['/wyniki-wyszukiwania']">
          <img
            src="assets/icons/mobile_global_search.png"
            width="20"
            height="20"
            alt="Ikonka globalnej wyszukiwarki"
          />
        </a>
      </span>
      <span class="menu material-icons" (click)="openMenu(mainMenuContent)">
        menu
      </span>
      <div class="account-options-desktop">
        <app-global-search-bar
          (isOpened)="globalSearchBarOpened = $event"
        ></app-global-search-bar>
        <a
          [ngClass]="{ opened: globalSearchBarOpened }"
          (click)="goToLogin()"
          [routerLink]="['/logowanie']"
          *ngIf="isLoggedOut$ | async"
          aria-labelledby="main-menu-login-icon-label"
        >
          <img
            src="assets/icons/account_dark.png"
            alt="Ikonka konta"
            width="14"
            height="20"
          />
          <span id="main-menu-login-icon-label">Zaloguj się</span>
        </a>
        <a
          [ngClass]="{ opened: globalSearchBarOpened }"
          [routerLink]="['/konto']"
          *ngIf="isLoggedIn$ | async"
          aria-labelledby="main-menu-account-icon-label"
        >
          <img src="assets/icons/account_dark.png" alt="Ikonka konta" />
          <span id="main-menu-account-icon-label">Moje konto</span>
        </a>
        <a
          [ngClass]="{ opened: globalSearchBarOpened }"
          *ngIf="isLoggedIn$ | async"
          (click)="logOut()"
          aria-labelledby="main-menu-logout-icon-label"
        >
          <img src="assets/icons/logout_dark.png" alt="Ikonka konta" />
          <span id="main-menu-logout-icon-label">Wyloguj</span>
        </a>
      </div>
    </div>
  </div>
  <div>
    <app-category-popup
      [mainMenuState]="mainMenuState"
      [mainMenuLayout]="mainMenu.layout"
      [alwaysVisible]="mainMenu.alwaysVisible"
    ></app-category-popup>
  </div>

  <div class="logo">
    <div class="logo__image">
      <a [routerLink]="['/']">
        <img
          class="logoImg"
          [strapiImgUrl]="
            logo?.getPublicImageUrl(
              getServerUrl() + '/strapi-proxy/' + tenantId
            )
          "
          [extension]="'.webp'"
          alt="Psychologia | PWN"
          width="300"
          height="63"
        />
      </a>
    </div>

    <img
      class="logo__glomerulus1"
      [strapiImgUrl]="
        glomerulus1?.getPublicImageUrl(
          getServerUrl() + '/strapi-proxy/' + tenantId
        )
      "
      [extension]="'.webp'"
      alt="Wiedza | PWN"
      width="65"
      height="35"
    />

    <div class="logo__text">
      <span>B</span>
      <span>L</span>
      <span>I</span>
      <span>Ż</span>
      <span>E</span>
      <span>J</span>
      <span>&nbsp;</span>
      <span>W</span>
      <span>I</span>
      <span>E</span>
      <span>D</span>
      <span>Z</span>
      <span>Y</span>
    </div>

    <img
      class="logo__glomerulus2"
      [strapiImgUrl]="
        glomerulus2?.getPublicImageUrl(
          getServerUrl() + '/strapi-proxy/' + tenantId
        )
      "
      [extension]="'.webp'"
      alt="Wiedza | PWN"
      width="65"
      height="35"
    />
  </div>
</div>

<ng-template #mainMenuContent let-modal>
  <span class="close-button material-icons" (click)="modal.close()">close</span>
  <div class="menu-options">
    <div *ngFor="let link of elements">
      <a
        *ngIf="link.type === componentTypes.CategoryLink"
        [routerLink]="['/artykuly', slugIdMap.get(link.targetId)]"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          link.external &&
          !link.page
        "
        href="{{ link.targetId }}"
        target="_blank"
        rel="noopener noreferrer nofollow"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          !link.external &&
          !link.page
        "
        [routerLink]="[link.targetId]"
        (click)="modal.close()"
      >
        {{ link.displayName | uppercase }}
      </a>
      <a
        *ngIf="
          link.type === componentTypes.StaticPageLink &&
          !link.external &&
          link.page
        "
      >
        <app-modal-popups
          [displayName]="link.displayName"
          [pageContent]="link.page?.content"
        ></app-modal-popups>
      </a>
    </div>
  </div>
  <div class="account-options">
    <a
      [routerLink]="['/logowanie']"
      *ngIf="isLoggedOut$ | async"
      (click)="goToLogin()"
      ><img src="assets/icons/account.png" alt="Ikonka konta" />Zaloguj się</a
    >
    <a
      [routerLink]="['/konto']"
      *ngIf="isLoggedIn$ | async"
      (click)="modal.close()"
      ><img src="assets/icons/account.png" alt="Ikonka konta" />Moje konto</a
    >
    <a *ngIf="isLoggedIn$ | async" (click)="logOut()"
      ><img src="assets/icons/logout.png" alt="Ikonka wylogowania" />Wyloguj</a
    >
  </div>
</ng-template>
