<div
  #container
  class="global-search-bar-container"
  [ngClass]="{ 'global-search-bar-opened': globalSearchBarOpened }"
>
  <div class="global-search-bar-contents">
    <div class="global-search-bar-wrapper">
      <a role="button" attr-aria-labelledby="global-search-bar-label">
        <img
          class="search-icon"
          src="assets/icons/global-search.png"
          (click)="search()"
          alt="Ikonka globalnej wyszukiwarki"
          width="17"
          height="17"
        />
        <span
          *ngIf="!globalSearchBarOpened"
          class="search-input"
          id="global-search-bar-label"
          >Szukaj</span
        >
      </a>
      <input
        [(ngModel)]="mainQuery"
        *ngIf="globalSearchBarOpened"
        type="text"
        (keyup.enter)="search()"
        [attr.aria-label]="'Wpisz szkukaną frazę'"
        #input
      />
    </div>
  </div>
</div>
