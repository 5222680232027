import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService } from '@shared/service/configuration/configuration.service';
import { Article, StrapiConfiguration } from '@moose/pwn-cms-model/lib';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { ArticleService } from '@shared/service/article/article.service';
import { PageService } from '@shared/service/page/page.service';

@Component({
  selector: 'app-closed-marker',
  templateUrl: './closed-marker.component.html',
  styleUrls: [
    './closed-marker.component.scss',
    './closed-marker.component.mobile.scss',
  ],
})
export class ClosedMarkerComponent implements OnInit, OnDestroy {
  @Input() set article(value: Article) {
    this._article = value;
    this.setClosedMarker();
  }
  _article: Article;
  config: StrapiConfiguration;
  closedMarkerHtml: SafeHtml;
  configSubscription: Subscription;
  isPayable: boolean;

  constructor(
    private articleService: ArticleService,
    private _sanitizer: DomSanitizer,
    private configService: ConfigurationService,
    public authService: AuthenticationService,
    private pageService: PageService
  ) {}

  ngOnInit(): void {
    this.configSubscription = this.configService
      .getConfiguration()
      .subscribe((strapiConfig: StrapiConfiguration) => {
        this.config = strapiConfig;
        this.setClosedMarker();
      });
  }

  ngOnDestroy(): void {
    this.configSubscription?.unsubscribe();
  }

  setClosedMarker() {
    let closeMarkerInfo = '';
    if (this.config && this._article) {
      if (
        this._article.paidUntil &&
        new Date(this._article.paidUntil).getTime() > new Date().getTime()
      ) {
        closeMarkerInfo = this.config.closedMarkerPaid;
        this.isPayable = true;
      } else {
        closeMarkerInfo = this.config.closedMarkerClosed;
        this.isPayable = false;
      }
    }
    this.closedMarkerHtml = this._sanitizer.bypassSecurityTrustHtml(
      closeMarkerInfo
    );
    return this.closedMarkerHtml;
  }

  setRedirects() {
    if (this.isPayable && !this.authService.isAuthenticated()) {
      this.pageService.setRedirectPath('/produkty');
    }
    this.articleService.setRedirectArticleId(this._article.id);
  }
}
