import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShellComponent } from './shell.component';
import { AuthenticationGuard } from '@app/@shared/service/authentication/authentication.guard';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('../home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'sitemap.xml',
        loadChildren: () =>
          import('../sitemap/sitemap.module').then((m) => m.SitemapModule),
      },
      {
        path: 'ksiazki',
        loadChildren: () =>
          import('../books/books.module').then((m) => m.BooksModule),
      },
      {
        path: 'skale',
        loadChildren: () =>
          import('../scales/scales.module').then((m) => m.ScalesModule),
      },
      {
        path: 'artykuly-ksiazki',
        loadChildren: () =>
          import('../source-articles/source-articles.module').then(
            (m) => m.SourceArticlesModule
          ),
      },
      {
        path: 'o-nas',
        loadChildren: () =>
          import('../about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'video',
        loadChildren: () =>
          import('../video/video.module').then((m) => m.VideoModule),
      },
      {
        path: 'kontakt',
        loadChildren: () =>
          import('../contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'nasi-autorzy',
        loadChildren: () =>
          import('../authors/authors.module').then((m) => m.AuthorsModule),
      },
      {
        path: 'autor',
        loadChildren: () =>
          import('../author/author.module').then((m) => m.AuthorModule),
      },
      {
        path: 'artykul',
        loadChildren: () =>
          import('../article/article.module').then((m) => m.ArticleModule),
        children: [
          {
            path: ':id',
            loadChildren: () =>
              import('../article/article.module').then((m) => m.ArticleModule),
          },
        ],
      },
      {
        path: 'zostan-autorem',
        loadChildren: () =>
          import('../become-author/become-author.module').then(
            (m) => m.BecomeAuthorModule
          ),
      },
      {
        path: 'loza-ekspertow',
        loadChildren: () =>
          import('../experts/experts.module').then((m) => m.ExpertsModule),
      },
      {
        path: 'oferty-pracy',
        loadChildren: () =>
          import('../job-offers/job-offers.module').then(
            (m) => m.JobOffersModule
          ),
      },
      {
        path: 'wydarzenia',
        loadChildren: () =>
          import('../events/events.module').then((m) => m.EventsModule),
      },
      {
        path: 'wyniki-wyszukiwania',
        loadChildren: () =>
          import('../search-results/search-results.module').then(
            (m) => m.SearchResultsModule
          ),
      },
      {
        path: 'strona',
        loadChildren: () =>
          import('../page/page.module').then((m) => m.PageModule),
      },
      {
        path: 'zgoda',
        loadChildren: () =>
          import('../agreement/agreement.module').then(
            (m) => m.AgreementModule
          ),
      },
      {
        path: 'zakonczenie-platnosci',
        loadChildren: () =>
          import('../product/payment-end/payment-end.module').then(
            (m) => m.PaymentEndModule
          ),
      },
      {
        path: 'konto',
        canActivate: [AuthenticationGuard],
        loadChildren: () =>
          import('../account/account.module').then((m) => m.AccountModule),
      },
      {
        path: 'nie-znaleziono',
        loadChildren: () =>
          import('../not-found/not-found.module').then((m) => m.NotFoundModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ShellRoutingModule {}
