<div class="content-desktop-container" *ngIf="isBrowser()">
  <h1>{{ component.title }}</h1>
  <app-promoted-book
    *ngFor="let book of component.books"
    [book]="book"
    [bookImageDisplayType]="component.bookImageDisplayType"
  >
  </app-promoted-book>
</div>

<div class="content-mobile-container">
  <div class="component-title">
    <h1>{{ component.title }}</h1>
  </div>
  <app-promoted-book
    *ngFor="let book of component.books"
    [book]="book"
    [bookImageDisplayType]="component.bookImageDisplayType"
  >
  </app-promoted-book>
</div>
