import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselModule } from 'primeng/carousel';

import { PartnerListComponent } from '@app/partner-list/partner-list.component';
import { PartnerLogoComponent } from '@app/partner-list/partner-logo/partner-logo.component';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [PartnerListComponent, PartnerLogoComponent],
  imports: [CommonModule, CarouselModule, SharedModule],
  exports: [PartnerListComponent],
})
export class PartnerListModule {}
