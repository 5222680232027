import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './article.component';
import { ArticleRoutingModule } from '@app/article/article-routing.module';
import { ArticleMainContentComponent } from './article-main-content/article-main-content.component';
import { ArticleSideContentComponent } from './article-side-content/article-side-content.component';
import { LargeFooterModule } from '@app/large-footer/large-footer.module';
import { ArticleHeaderComponent } from './article-header/article-header.component';
import { ArticleBodyComponent } from './article-body/article-body.component';
import { PartnerListModule } from '@app/partner-list/partner-list.module';
import { ArticleAuthorInfoComponent } from '@app/article/article-author-info-list/article-author-info/article-author-info.component';
import { ArticleAuthorInfoListComponent } from './article-author-info-list/article-author-info-list.component';
import { AdModule } from '@app/ad/ad.module';
import { ArticleTopContentComponent } from '@app/article/article-top-content/article-top-content.component';
import { ArticleBottomContentComponent } from '@app/article/article-bottom-content/article-bottom-content.component';
import { ClosedMarkerComponent } from './closed-marker/closed-marker.component';
import { ArticleSourcesComponent } from './article-sources/article-sources.component';
import { ImageGalleryModule } from '@app/image-gallery/image-gallery.module';
import { StaticTextModule } from '@app/static-text/static-text.module';
import { BookstoreModule } from '@app/bookstore/bookstore.module';
import { NewsletterModule } from '@app/newsletter/newsletter.module';
import { SocialMediaModule } from '@app/social-media/social-media.module';
import { SharedModule } from '@shared';
import { ParagraphModule } from '@app/paragraph/paragraph.module';
import { ArticleAttachmentListComponent } from './article-attachment-list/article-attachment-list.component';
import { ArticleAttachmentComponent } from './article-attachment-list/article-attachment/article-attachment.component';
import { CustomHtmlComponent } from '@app/custom-html/custom-html.component';
import { ReadAlsoListModule } from '@app/read-also-list/read-also-list.module';
import { ContextualAdComponent } from './contextual-ad/contextual-ad.component';
import { AdVideoModule } from '@app/ad-video/ad-video.module';

@NgModule({
  declarations: [
    ArticleComponent,
    ArticleMainContentComponent,
    ArticleSideContentComponent,
    ArticleHeaderComponent,
    ArticleBodyComponent,
    ArticleAuthorInfoComponent,
    ArticleAuthorInfoListComponent,
    ArticleTopContentComponent,
    ArticleBottomContentComponent,
    ClosedMarkerComponent,
    ArticleSourcesComponent,
    ArticleAttachmentComponent,
    ArticleAttachmentListComponent,
    CustomHtmlComponent,
    ContextualAdComponent,
  ],
  imports: [
    CommonModule,
    ArticleRoutingModule,
    LargeFooterModule,
    PartnerListModule,
    AdModule,
    AdVideoModule,
    ImageGalleryModule,
    StaticTextModule,
    BookstoreModule,
    NewsletterModule,
    SocialMediaModule,
    SharedModule,
    ParagraphModule,
    ReadAlsoListModule,
  ],
  exports: [
    ArticleHeaderComponent,
    CustomHtmlComponent,
    ArticleAttachmentListComponent,
  ],
})
export class ArticleModule {}
