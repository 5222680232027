<a [routerLink]="['/artykuly', slugIdMap.get(category.id)]">
  <h2 [ngStyle]="{ color: category.color }">{{ category.displayName }}</h2>
</a>
<div *ngFor="let article of articles">
  <hr [ngStyle]="{ 'background-color': category.color || 'black' }" />
  <app-top-article
    [article]="article"
    [showDates]="showDates"
  ></app-top-article>
</div>
<a [routerLink]="['/artykuly', slugIdMap.get(category.id)]" class="desktop"
  >{{ category.readMoreLabel }}
  <i role="presentation" class="material-icons">trending_flat</i></a
>
<div class="read-more-mobile-container">
  <a
    [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
    aria-label="Czytaj więcej"
  >
    <span>{{ category.readMoreLabel }}</span>
  </a>
</div>
