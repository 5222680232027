import { Component, Input, OnInit } from '@angular/core';
import {
  Article,
  StrapiComponentTypeEnum,
  StrapiImage,
  StrapiImageSizeEnum,
} from '@moose/pwn-cms-model/lib';

import { environment } from '@env/environment';

@Component({
  selector: 'app-article-body',
  templateUrl: './article-body.component.html',
  styleUrls: [
    './article-body.component.scss',
    './article-body.component.mobile.scss',
  ],
})
export class ArticleBodyComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  public imageFormat = StrapiImageSizeEnum.small;

  @Input()
  article: Article;

  constructor() {}

  ngOnInit(): void {}

  getServerUrl(): string {
    return environment.serverUrl;
  }

  getImageAlt(imageObj: StrapiImage): string {
    if (imageObj.alternativeText && imageObj.alternativeText.length) {
      return imageObj.alternativeText;
    }
    return 'Obrazek w artykule';
  }
}
