<div class="read-also-container" *ngIf="articles?.length > 0">
  <div class="content-container">
    <div class="title">
      <h2>{{ component.title }}</h2>
    </div>

    <div class="content">
      <app-read-also
        *ngFor="let relatedArticle of articles"
        [article]="relatedArticle"
        [readMore]="
          component.readMoreLabel ? component.readMoreLabel : 'Czytaj więcej'
        "
      >
      </app-read-also>
    </div>
  </div>
</div>
