<div class="sources-container" *ngIf="visible">
  <div *ngFor="let item of items" class="item-container">
    <div class="item-left-container">
      <div class="item-inner-container">
        <a
          href="{{ item.linkUrl }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img
            class="item-image"
            *ngIf="item.image && item.image.url"
            [imgUrlWithSize]="imageFormat"
            [imgUrl]="item.image.url"
            [alt]="getImageAlt(item.image, item.title)"
            [style]="'object-fit: ' + component.displayType"
            loading="lazy"
          />
        </a>
      </div>
    </div>
    <div class="item-right-container">
      <a
        class="item-title"
        *ngIf="item.isMagazine"
        href="{{ item.linkUrl }}"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        Artykuł z magazynu “{{ item.title }}”
      </a>
      <a
        class="item-title"
        *ngIf="!item.isMagazine"
        href="{{ item.linkUrl }}"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <div>
          <span>Artykuł z książki "{{ item.title }}"</span>
        </div>
      </a>
      <p
        class="item-description"
        *ngIf="item.description"
        [innerHTML]="item.description"
      ></p>
      <a
        class="button"
        *ngIf="item.customLinkUrl"
        target="_blank"
        href="{{ item.customLinkUrl }}"
        rel="noopener noreferrer nofollow"
      >
        <span *ngIf="item.customLinkName">{{ item.customLinkName }}</span>
      </a>
      <!-- <a class="item-link" [routerLink]="['/artykuly-ksiazki', item.id]">
        <span *ngIf="item.isMagazine" id="article-sources-cmp-goto-label">Przejdź do artykułów z magazynu </span>
        <span *ngIf="!item.isMagazine" id="article-sources-cmp-goto-label">Przejdź do artykułów z książki </span>
        <span role="presentation" class="arrow-icon material-icons" aria-labelledby="article-sources-cmp-goto-label"
          >trending_flat</span
        >
      </a> -->
    </div>
  </div>
  <div class="sources-external" [innerHtml]="getSourcesExternalHtml()"></div>
</div>
