<div class="contextual-ad-container">
  <div class="contextual-ad-titles">
    <div class="contextual-ad-primary-title">{{ _component.primaryTitle }}</div>
    <hr />
    <div class="contextual-ad-secondary-title">
      {{ _component.secondaryTitle | uppercase }}
    </div>
  </div>
  <div class="contextual-ad-content" [innerHTML]="content"></div>
  <a
    href="{{ _component.productUrl }}"
    target="_blank"
    rel="noopener noreferrer"
    class="contextual-ad-button"
  >
    {{ _component.buttonTitle }}
    <span class="material-icons arrow-icon" role="presentation"
      >trending_flat</span
    >
  </a>
  <hr />
</div>
