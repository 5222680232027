<div class="small-footer-container-desktop">
  <footer>
    <div *ngIf="smallFooter">
      <span class="copyright-span">{{ smallFooter.copyright }}</span>
      <span *ngFor="let link of links">
        <a
          *ngIf="link.externalLink"
          href="{{ link.externalLink }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          >{{ link.displayName }}</a
        >
        <a *ngIf="link.internalLink" [routerLink]="link.internalLink">{{
          link.displayName
        }}</a>
        <a
          *ngIf="!link.externalLink && !link.internalLink"
          rel="noopener noreferrer nofollow"
        >
          <app-modal-popups
            [displayName]="link.displayName"
            [pageContent]="link.page?.content"
          ></app-modal-popups>
        </a>
      </span>
    </div>
  </footer>
</div>

<div class="small-footer-container-mobile">
  <footer>
    <div *ngIf="smallFooter">
      <span *ngFor="let link of smallFooter.links">
        <a
          *ngIf="link.externalLink"
          href="{{ link.externalLink }}"
          target="_blank"
          rel="noopener noreferrer nofollow"
          >{{ link.displayName }}</a
        >
        <a *ngIf="link.internalLink" [routerLink]="link.internalLink">{{
          link.displayName
        }}</a>
        <a
          *ngIf="!link.externalLink && !link.internalLink"
          rel="noopener noreferrer nofollow"
        >
          <app-modal-popups
            [displayName]="link.displayName"
            [pageContent]="link.page?.content"
          ></app-modal-popups>
        </a>
      </span>
      <div class="copyright-container">
        <span class="copyright-span">{{ smallFooter.copyright }}</span>
      </div>
    </div>
  </footer>
</div>
