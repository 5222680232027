import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

import { UrlService } from '@shared/service/url/url.service';

@Directive({
  selector: '[imgUrlWithSize]',
})
export class ImgUrlWithSizeDirective implements OnInit {
  @Input('imgUrlWithSize') size: string;
  @Input() imgUrl: string;

  @Output() loaded: boolean;

  private imgErrorListener: Function;
  private imgLoadListener: Function;
  private imgSeccondErrorListener: Function;

  constructor(
    private urlService: UrlService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.imgUrl && this.size) {
      this.downloadImage();
    } else {
      this.displayError();
    }
  }

  getImageUrlWithSize(url: string, size: string): string[] {
    return this.urlService.getImageUrlWithSize(url, size);
  }

  downloadImage() {
    this.renderer.setStyle(
      this.el.nativeElement,
      'background',
      'url(assets/icons/loader.svg) center no-repeat'
    );
    const urlsList = this.getImageUrlWithSize(this.imgUrl, this.size);
    const webpUrl = urlsList[0].replace(/\..[^.]+$/gi, '.webp');
    this.renderer.setAttribute(this.el.nativeElement, 'src', webpUrl);
    // this.renderer.setAttribute(this.el.nativeElement, 'src', urlsList[0]);

    // Hide loading spinner on load
    this.imgLoadListener = this.renderer.listen(
      this.el.nativeElement,
      'load',
      () => {
        this.imgLoadListener();
        this.renderer.removeStyle(this.el.nativeElement, 'background');
      }
    );

    // Load alternative image on error
    this.imgErrorListener = this.renderer.listen(
      this.el.nativeElement,
      'error',
      () => {
        // Remove error listener to avoid looping - it's strange to remove by call this function, but it works :)
        this.imgErrorListener();

        this.renderer.setAttribute(this.el.nativeElement, 'src', urlsList[1]);

        // Handle error on alternative download
        this.imgSeccondErrorListener = this.renderer.listen(
          this.el.nativeElement,
          'error',
          () => {
            // Remove error listener to avoid looping
            this.imgSeccondErrorListener();

            // Remove on load listener
            this.imgLoadListener();

            this.renderer.removeStyle(this.el.nativeElement, 'background');
          }
        );
      }
    );
  }

  displayError() {
    let lostValues = 'Lost values on ImgUrlWithSizeDirective:';
    if (!this.size) {
      lostValues = `${lostValues} imgUrlWithSize;`;
    }
    if (!this.imgUrl) {
      lostValues = `${lostValues} imgUrl;`;
    }
    console.error(lostValues);
  }
}
