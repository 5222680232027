import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/@shared/service/authentication/authentication.service';
import { Logger } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('ShellComponent');

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss', './shell.component.mobile.scss'],
})
export class ShellComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private modal: NgbModal
  ) {}

  ngOnInit() {
    log.debug('Component init started..');
    console.log('wersja UI 1.3.19.4');
  }

  showRegisterExistingForm(): boolean {
    return (
      this.authService.credentials !== null &&
      this.authService.credentials.fillRegistrationData === true
    );
  }
}
