<app-search-bar
  (resetPage)="changePage($event, true)"
  [data]="searchData"
  (searchBarResult)="parseSearchResult($event)"
  [pageChanged]="page"
  [searchForFavorites]="showFavoriteLabel"
  [searchType]="searchType"
></app-search-bar>

<div class="article-list-container">
  <div class="article-container" *ngFor="let article of articles">
    <hr />
    <div class="article-top-bar">
      <div class="tags">
        <span
          *ngFor="let category of article.categories"
          class="tag category"
          [ngStyle]="{ color: categoriesColors.get(category.id) }"
          [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
        >
          {{ category.name | uppercase }}
        </span>
        <!-- PWN-313 zostawiam zakomentowane w razie potrzeby cofnięcia tej zmiany -->
        <!-- <span *ngFor="let tag of article.tags" class="tag">
          {{ tag.name | uppercase }}
        </span> -->
        <span class="date" *ngIf="article.date">{{
          article.date | date: 'dd.MM.yyyy'
        }}</span>
      </div>
      <div class="article-controls">
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && !isFavorite(article.id)"
          (click)="addToFavorite(article.id)"
        >
          <p class="article-control-text" *ngIf="showFavoriteLabel">
            Dodaj do ulubionych
          </p>
          <span class="material-icons favorite-icon-add">favorite_border</span>
        </button>
        <button
          class="article-control-item"
          *ngIf="(isLoggedIn$ | async) && isFavorite(article.id)"
          (click)="removeFromFavorite(article.id)"
        >
          <p class="article-control-text" *ngIf="showFavoriteLabel">
            Usuń z ulubionych
          </p>
          <span class="material-icons favorite-icon-remove">favorite</span>
        </button>
      </div>
    </div>
    <p class="title">
      <a
        [routerLink]="[
          '/artykul',
          UrlUtils.toSlugId(article.title, article.id)
        ]"
        [innerHtml]="article.title"
      ></a>
    </p>
    <p class="lead" [innerHTML]="getLeadHtml(article)"></p>
    <span class="mobile-date" *ngIf="article.date">{{
      article.date | date: 'dd.MM.yyyy'
    }}</span>
    <a
      class="read-link"
      [routerLink]="['/artykul', UrlUtils.toSlugId(article.title, article.id)]"
    >
      <span id="article-list-cmp-readmore-label">Czytaj</span>
      <span
        role="presentation"
        class="arrow-icon material-icons"
        aria-labelledby="article-list-cmp-readmore-label"
        >trending_flat</span
      >
    </a>
  </div>
  <hr class="last-article" />

  <div *ngIf="!articles" class="article-container nothing-found-container">
    <div class="search-result-loading-placeholder"></div>
  </div>
  <div
    *ngIf="articles && articles.length === 0"
    class="article-container nothing-found-container"
  >
    <p class="title">
      Niestety, nie udało nam się niczego znaleźć
    </p>
    <p class="lead gray-lead">Sprawdź pisownię, uogólnij szukaną frazę</p>
  </div>
  <ngb-pagination
    class="desktop-pagination"
    [page]="page || 1"
    [pageSize]="itemsPerPage"
    [collectionSize]="totalElements"
    [rotate]="true"
    [boundaryLinks]="true"
    [maxSize]="3"
    [ngClass]="{
      'hidden-previous-button': page === 1 || page === undefined,
      'hidden-next-button': page === this.totalPages || this.totalPages === 1
    }"
    (pageChange)="changePage($event)"
    *ngIf="articles?.length > 0"
  >
    <ng-template ngbPaginationPrevious>poprzednia</ng-template>
    <ng-template ngbPaginationNext>następna</ng-template>
  </ngb-pagination>

  <ngb-pagination
    class="mobile-pagination"
    [page]="page || 1"
    [pageSize]="itemsPerPage"
    [collectionSize]="totalElements"
    [rotate]="true"
    [directionLinks]="false"
    [maxSize]="3"
    [ngClass]="{
      'hidden-previous-button': page === 1 || page === undefined,
      'hidden-next-button': page === this.totalPages || this.totalPages === 1
    }"
    (pageChange)="changePage($event)"
    *ngIf="articles?.length > 0"
  >
  </ngb-pagination>
</div>
