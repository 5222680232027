import { Component, Input, OnInit } from '@angular/core';
import {
  SimpleArticle,
  SimpleCategory,
  StrapiCategory,
  StrapiReadAlsoComponent,
} from '@moose/pwn-cms-model/lib';
import { CategoryService } from '@shared/service/category/category.service';

@Component({
  selector: 'app-read-also-list',
  templateUrl: './read-also-list.component.html',
  styleUrls: [
    './read-also-list.component.scss',
    './read-also-list.component.mobile.scss',
  ],
})
export class ReadAlsoListComponent implements OnInit {
  @Input() component: StrapiReadAlsoComponent;

  @Input() set relatedArticles(data: SimpleArticle[]) {
    this.articles = [];
    data?.forEach((relatedArticle: SimpleArticle) => {
      const categories: SimpleCategory[] = [];
      relatedArticle.categories.forEach((cat) => {
        this.categoryService
          .getCategoryById(cat.id)
          .subscribe((response: StrapiCategory) => {
            const category = new SimpleCategory();
            category.id = response.id;
            category.name = response.displayName;
            category.color = response.color;
            categories.push(category);
          });
        relatedArticle.categories = categories;
      });

      this.articles.push(relatedArticle);
    });
  }

  articles: SimpleArticle[] = [];

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {}
}
