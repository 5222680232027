<div class="content-container" *ngIf="treeNodes?.length > 1">
  <p-tree
    [value]="treeNodes"
    selectionMode="multiple"
    [(selection)]="selectedNodes"
    (onNodeSelect)="onNodeSelect()"
  >
    <ng-template let-node pTemplate="default">
      <div
        *ngIf="
          showMore(node.data) ||
          node.parent === null ||
          node.parent?.children.indexOf(node) <
            _component.subCategoriesToDisplay
        "
      >
        <div
          *ngIf="
            selectedNodes.indexOf(node) > -1 &&
            (node?.children === null || node?.children?.length === 0)
          "
          class="selected-marker"
        ></div>
        <p class="node-label">
          {{ node.label }}
        </p>
      </div>

      <div
        class="button-container p-tree-toggler"
        *ngIf="
          !showMore(node.data) &&
          node.parent !== null &&
          node.parent?.children.indexOf(node) ===
            _component.subCategoriesToDisplay
        "
      >
        <button class="p-tree-toggler" (click)="changeVisibility(node.data)">
          Rozwiń więcej
        </button>
      </div>

      <div
        class="button-container p-tree-toggler"
        *ngIf="
          showMore(node.data) &&
          node.parent !== null &&
          node.parent?.children.indexOf(node) >
            _component.subCategoriesToDisplay &&
          node.parent?.children.indexOf(node) ===
            node.parent?.children?.length - 1
        "
      >
        <button class="p-tree-toggler" (click)="changeVisibility(node.data)">
          Zwiń
        </button>
      </div>

      <div #thisRef>
        <div
          *ngIf="
            (!showMore(node.data) &&
              node.parent !== null &&
              node.parent?.children?.indexOf(node) >
                _component.subCategoriesToDisplay &&
              hideElement(thisRef)) ||
            showElement(thisRef)
          "
        ></div>
      </div>
    </ng-template>
  </p-tree>

  <div class="category-list-button">
    <button (click)="openCategoryList(mobileCategoryList)">
      Wybierz kategorię
    </button>
  </div>
</div>

<ng-template #mobileCategoryList let-modal>
  <div class="category-modal-header-container">
    <p class="category-modal-header"></p>
    <span class="close-button material-icons" (click)="modal.close()"
      >close</span
    >
  </div>
  <div class="category-list">
    <button
      *ngFor="let node of flatTreeNodes"
      (click)="redirectToCategory(node.data.id)"
    >
      <span *ngIf="node.parent">{{ node.data.displayName }}</span>
      <span *ngIf="!node.parent">{{ node.label }}</span>
    </button>
  </div>
</ng-template>
