export class GalleryImage {
  url!: string;
  title?: string;
  description?: string;

  constructor(url: string, title?: string, description?: string) {
    this.url = url;
    this.title = title;
    this.description = description;
  }
}
