<div [ngSwitch]="currentState?.type" class="breadcrumb-container">
  <p *ngSwitchCase="BreadcrumbType.article">
    <a [routerLink]="'/'">Strona Główna</a>
    <span *ngFor="let node of articleTreeNode?.getPath()">
      /
      <a [routerLink]="['/artykuly', slugIdMap.get(node.element.id)]">{{
        node.element.shortName || node.element.displayName
      }}</a>
    </span>
    /
    <a [routerLink]="['/artykul', article.slugId]">
      {{ article.title }}
    </a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.author">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/nasi-autorzy'">Autorzy</a>
    /
    <a [routerLink]="['/autor', UrlUtils.toSlugId(author.name, author.id)]">{{
      author.name
    }}</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.category">
    <span *ngIf="category.parent">
      <a [routerLink]="'/'">Strona Główna</a>
      <span *ngFor="let node of category.getPath()">
        /
        <a
          [routerLink]="[
            isBooks ? '/ksiazki' : '/artykuly',
            slugIdMap.get(node.element.id)
          ]"
          >{{ node.element.shortName || node.element.displayName }}</a
        >
      </span>
    </span>
  </p>
  <p *ngSwitchCase="BreadcrumbType.event">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/wydarzenia'">Wydarzenia</a>
    /
    <a
      [routerLink]="['/wydarzenia', UrlUtils.toSlugId(event.title, event.id)]"
      >{{ event.title }}</a
    >
  </p>
  <p *ngSwitchCase="BreadcrumbType.jobOffer">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/oferty-pracy'">OFERTY PRACY</a>
    /
    <a
      [routerLink]="[
        '/oferty-pracy',
        UrlUtils.toSlugId(jobOffer.title, jobOffer.id)
      ]"
      >{{ jobOffer.title }}</a
    >
  </p>
  <p *ngSwitchCase="BreadcrumbType.calendar">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/wydarzenia'">Wydarzenia</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.newest">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a href="#" (click)="stayOnPage($event)">Artykuły</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.books">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/ksiazki'">Książki</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.authors">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a [routerLink]="'/nasi-autorzy'">Autorzy</a>
  </p>
  <p *ngSwitchCase="BreadcrumbType.about">
    <a [routerLink]="'/'">Strona Główna</a>
    /
    <a href="#" (click)="stayOnPage($event)">O Nas</a>
  </p>
</div>
