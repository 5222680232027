import { Injectable } from '@angular/core';
import { StrapiImage, StrapiImageClass } from '@moose/pwn-cms-model/lib';

import { environment } from '@env/environment';

enum Providers {
  aws = 'aws-s3',
  local = 'local',
}

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  readonly tenantId = environment.tenantId;

  /**
   * Transform image url to get it with optymised format, e.g. '.../some_url/image.jpg' -> '.../some_url/small_image.jpg';
   * @param  {string} url Orginal url of image without base url;
   * @param  {string} size Required size of image; Allowed value 'thumbnail', 'small', 'medium', 'large', ...; Check StrapiImageSizeEnum to get all allowed values;
   * @returns string[]
   */
  getImageUrlWithSize(url: string, size: string): string[] {
    const provider =
      environment.provider === 'local' ? Providers.local : Providers.aws;

    return new StrapiImageClass({
      url,
      provider,
    } as StrapiImage).getPublicImageUrlWithSize(
      environment.serverUrl + '/strapi-proxy/' + this.tenantId,
      size
    );
  }

  /**
   * Get full image url for specific environment
   * @param  {string} url Url of the image without base url
   * @returns string
   */
  getImageUrl(url: string): string {
    const provider =
      environment.provider === 'local' ? Providers.local : Providers.aws;

    return new StrapiImageClass({
      url,
      provider,
    } as StrapiImage).getPublicImageUrl(
      environment.serverUrl + '/strapi-proxy/' + this.tenantId
    );
  }
}
