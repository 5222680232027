import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import {
  Article,
  UrlUtils,
  StrapiImageSizeEnum,
  Image,
} from '@moose/pwn-cms-model/lib';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { Observable, Subscription } from 'rxjs';
import { UserProductService } from '@shared/service/user-product/user-product.service';
import { Router } from '@angular/router';
import { CategoryService } from '@shared/service/category/category.service';
import { TreeNode } from 'primeng/api';
import { SearchService } from '@app/@shared/service/elastic-search/search.service';

@Component({
  selector: 'app-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: [
    './article-header.component.scss',
    './article-header.component.mobile.scss',
  ],
})
export class ArticleHeaderComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  _article: Article;
  articleDisplayDate = '';
  userFavoriteArticlesIds: string[] = [];
  isLoggedIn$: Observable<boolean>;
  userFavoriteArticleIds$: Subscription;
  categoryTreeSubscription: Subscription;

  public imageArticleFormat = StrapiImageSizeEnum.large;
  public imageAuthorFormat = StrapiImageSizeEnum.thumbnail;

  @Input() set article(value: Article) {
    if (value) {
      this._article = value;
      if (value.displayDate) {
        this.articleDisplayDate = this.getDisplayDate(value.displayDate);
      } else if (value.publishDate) {
        this.articleDisplayDate = this.getDisplayDate(value.publishDate);
      }
    }
  }

  @Output() downloadPdfEvent = new EventEmitter();

  constructor(
    private authenticationService: AuthenticationService,
    private userProductService: UserProductService,
    private router: Router,
    private categoryService: CategoryService,
    private searchService: SearchService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticlesIds = value;
      }
    );
  }

  ngOnDestroy(): void {
    this.userFavoriteArticleIds$?.unsubscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  getDisplayDate(date: string): string {
    return moment(date).locale('pl').format('LL');
  }

  downloadPdfRequest(event: HTMLElement) {
    this.renderer.addClass(event, 'animation');
    setTimeout(() => {
      this.renderer.removeClass(event, 'animation');
    }, 600);
    this.downloadPdfEvent.emit();
  }

  isProduction(): boolean {
    return environment.production;
  }

  isFavorite(articleId: string): boolean {
    if (this.userFavoriteArticlesIds) {
      const index = this.userFavoriteArticlesIds.findIndex(
        (favoriteId) => favoriteId === articleId
      );
      return index > -1;
    } else {
      return false;
    }
  }

  addToFavorite() {
    if (this.isLoggedIn$) {
      this.userProductService
        .addArticleToFavorite(this._article.id)
        .subscribe((res) => {
          if (!this.userFavoriteArticlesIds) {
            this.userFavoriteArticlesIds = [];
          }
          if (
            res &&
            !this.userFavoriteArticlesIds.find(
              (favoriteId) => favoriteId === this._article.id
            )
          ) {
            this.userFavoriteArticlesIds.push(this._article.id);
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
        });
    }
  }

  removeFromFavorite() {
    if (this.isLoggedIn$) {
      this.userProductService
        .removeArticleFromFavorite(this._article.id)
        .subscribe((res) => {
          if (res && this.userFavoriteArticlesIds) {
            const productIndex = this.userFavoriteArticlesIds.indexOf(
              this._article.id
            );
            if (productIndex > -1) {
              this.userFavoriteArticlesIds.splice(productIndex, 1);
            }
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
        });
    }
  }

  searchByTagName(tagName: string) {
    this.categoryTreeSubscription = this.categoryService
      .getTreeNodes(this._article.categories[0].id)
      .subscribe((treeNodes: TreeNode[]) => {
        const array: TreeNode[] = [];
        const parentCategory = this.findParent(treeNodes[0], array);

        // TODO Figure out why navigating to search with tag causes duplicated search request and remove this workaround
        this.searchService.ignoreNextRouterEvent();
        this.router.navigate([`artykuly/` + parentCategory], {
          queryParams: { query: tagName },
          queryParamsHandling: 'merge',
        });
      });
  }

  setImageAlt() {
    return this._article.img &&
      this._article.img.alternativeText &&
      this._article.img.alternativeText.length
      ? this._article.img.alternativeText
      : this._article.title;
  }

  private findParent(node: TreeNode, array: TreeNode[]) {
    node.expanded = true;
    array.push(node);
    if (node.parent) {
      this.findParent(node.parent, array);
    } else {
      return UrlUtils.toSlugId(node.label, node.data.id);
    }
  }
}
