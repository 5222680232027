import { Injectable } from '@angular/core';
import { AdContext } from '@shared/model/ad/ad-context';
import {
  SimpleCategory,
  StrapiAdZone,
  StrapiAdZoneConfigComponent,
} from '@moose/pwn-cms-model/lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../authentication/authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AdService {
  adContext: AdContext = new AdContext();
  ctx = new BehaviorSubject(this.adContext);
  private readonly tenantId = environment.tenantId;

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) {
    this.authService.user$.subscribe((userData) => {
      if (userData == null || userData == undefined) {
        this.adContext.pwz = null;
      } else {
        this.adContext.pwz = userData.pwzNumber;
      }
    });
  }

  getAdZone(id: string): Observable<StrapiAdZone> {
    console.log('Getting adZone: ' + id);
    return this.http.get<StrapiAdZone>(
      `/strapi-proxy/${this.tenantId}/ad-zone/${id}`
    );
  }

  getXmlData(url: string): any {
    return this.http.get(url, { responseType: 'text' });
  }

  getCtx(): Observable<AdContext> {
    return this.ctx;
  }

  setAdContext(categories: SimpleCategory[]) {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}-${currentDate.getFullYear()} ${currentDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
    console.log('Setting ad context' + ' at ' + formattedDate);
    this.adContext.categories = [];
    if (categories != null && categories !== undefined) {
      for (const cat of categories) {
        this.adContext.categories.push(cat.id);
      }
    }
    this.ctx.next(this.adContext);
  }

  setAdContextWithIds(categories: string[]) {
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, '0')}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}-${currentDate.getFullYear()} ${currentDate
      .getHours()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
    console.log('Setting ad contextIds' + ' at ' + formattedDate);
    this.adContext.categories = categories;
    this.ctx.next(this.adContext);
  }

  getAdUrl(zone: StrapiAdZone, isDesktop: boolean): string {
    console.log('getting ad: zone: ' + zone?.id + ' isDesktop: ' + isDesktop);
    if (zone == null || zone === undefined) {
      console.log('UNDEFINED ZONE!');
      return '';
    }

    // if there's no config map then just return default values
    if (
      zone.configMap === null ||
      zone.configMap === undefined ||
      zone.configMap.length === 0
    ) {
      if (isDesktop) {
        console.log('returning NCM desktopUrl: ' + zone.desktopUrl);
        return zone.desktopUrl;
      } else {
        console.log('returning NCM mobileUrl: ' + zone.desktopUrl);
        return zone.mobileUrl;
      }
    }

    // sort configMap
    zone.configMap.sort(
      (
        a: StrapiAdZoneConfigComponent,
        b: StrapiAdZoneConfigComponent
      ): number => {
        if (a.order > b.order) {
          return 1;
        }
        return -1;
      }
    );

    // now verify 1 by 1 if config matches current context. If it does then return values from config
    for (const config of zone.configMap) {
      if (this.pwzMatches(config) && this.categoryMatches(config)) {
        if (isDesktop) {
          console.log('returning CM desktopUrl: ' + config.desktopUrl);
          console.log('entry:');
          console.log(JSON.stringify(config));
          return config.desktopUrl;
        } else {
          console.log('returning CM mobileUrl: ' + config.mobileUrl);
          console.log('entry:');
          console.log(JSON.stringify(config));
          return config.mobileUrl;
        }
      }
    }

    // nothing matches - return default values
    if (isDesktop) {
      console.log('returning desktopUrl: ' + zone.desktopUrl);
      return zone.desktopUrl;
    } else {
      console.log('returning mobileUrl: ' + zone.mobileUrl);
      return zone.mobileUrl;
    }
  }

  private pwzMatches(cfg: StrapiAdZoneConfigComponent): boolean {
    // if there is no restriction on PWZ in context config then return true
    if (
      cfg.pwz == null ||
      cfg.pwz === undefined ||
      cfg.pwz.trim().length === 0
    ) {
      return true;
    }
    // there is restriction, so if we don't have PWZ in current context then return false
    if (
      this.adContext.pwz == null ||
      this.adContext.pwz === undefined ||
      this.adContext.pwz.trim().length === 0
    ) {
      return false;
    }
    // there is restriction and we have PWZ in current context - verify if matches
    const matchArray = this.adContext.pwz.match(cfg.pwz);
    return matchArray != null && matchArray.length > 0;
  }

  private categoryMatches(cfg: StrapiAdZoneConfigComponent): boolean {
    // if there is no restriction on categories then return true
    if (
      cfg.categories == null ||
      cfg.categories === undefined ||
      cfg.categories.length === 0
    ) {
      return true;
    }

    // there is restriction on catgories but no categories are defined in current context - return false
    if (
      this.adContext.categories == null ||
      this.adContext.categories === undefined ||
      this.adContext.categories.length === 0
    ) {
      return false;
    }

    // there is restriction and we have categories in context - if any category from context matches
    // any category in config then return true.
    for (const category1 of this.adContext.categories) {
      for (const category2 of cfg.categories) {
        if (category1 === category2.id) {
          return true;
        }
      }
    }

    // false otherwise
    return false;
  }
}
