import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Article,
  StrapiAdComponent,
  StrapiArticleHeaderComponent,
  StrapiComponentTypeEnum,
} from '@moose/pwn-cms-model/lib';

@Component({
  selector: 'app-article-top-content',
  templateUrl: './article-top-content.component.html',
  styleUrls: ['./article-top-content.component.scss'],
})
export class ArticleTopContentComponent implements OnInit {
  @Input()
  components: (StrapiAdComponent | StrapiArticleHeaderComponent)[];

  @Input()
  article: Article;

  @Output() downloadPdfEvent = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  constructor() {}

  ngOnInit(): void {}

  downloadPdfRequest() {
    this.downloadPdfEvent.emit();
  }
}
