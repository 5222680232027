import { ElkDocumentObject } from '@moose/pwn-cms-model/lib';

export class SearchBarResult {
  data: ElkDocumentObject[];
  totalElements: number;
  totalPages: number;
  itemsPerPage: number;

  constructor(
    data?: ElkDocumentObject[],
    totalElements?: number,
    totalPages?: number,
    itemsPerPage?: number
  ) {
    this.data = data;
    this.totalElements = totalElements;
    this.totalPages = totalPages;
    this.itemsPerPage = itemsPerPage;
  }
}
