import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[strapiImgUrl]',
})
export class StrapiImgUrlDirective {
  @Input('strapiImgUrl')
  set url(imgUrl: string) {
    this.downloadImage(imgUrl);
  }
  @Input() extension: string;

  private imgErrorListener: Function;
  private imgLoadListener: Function;
  private imgSeccondErrorListener: Function;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  downloadImage(url: string) {
    this.renderer.setStyle(
      this.el.nativeElement,
      'background',
      'url(assets/icons/loader.svg) center no-repeat'
    );

    let imgUrl = '';
    if (this.extension) {
      imgUrl = url.replace(/\..[^.]+$/gi, this.extension);
    } else {
      imgUrl = url;
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', imgUrl);

    // Hide loading spinner on load
    this.imgLoadListener = this.renderer.listen(
      this.el.nativeElement,
      'load',
      () => {
        this.imgLoadListener();
        this.renderer.removeStyle(this.el.nativeElement, 'background');
      }
    );

    // Load alternative image on error
    this.imgErrorListener = this.renderer.listen(
      this.el.nativeElement,
      'error',
      () => {
        // Remove error listener to avoid looping - it's strange to remove by call this function, but it works :)
        this.imgErrorListener();

        this.renderer.setAttribute(this.el.nativeElement, 'src', url);

        console.warn(
          `Image '${imgUrl}' wasn't downloaded because required file with extension '${this.extension}' did not found. I'll try to download the original file instead.`
        );
      }
    );
  }
}
