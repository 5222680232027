<div
  class="toast-wrapper row mx-0 px-0 d-flex align-items-end justify-content-center"
>
  <div class="justify-content-center">
    <ngb-toast
      class="toast-container"
      *ngFor="let toast of toastService.toasts"
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 10000"
      (hide)="toastService.remove(toast)"
    >
      <div
        class="content-container row mx-0 px-0 d-flex align-items-center justify-content-between"
      >
        <div>
          <i class="fas fa-times" (click)="close(toast)"></i>
        </div>
        <div>
          <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
          </ng-template>

          <ng-template #text>{{ toast.textOrTpl }}</ng-template>
        </div>
      </div>
    </ngb-toast>
  </div>
</div>
