<div class="title-container">
  <span class="title-container__text">{{ text }}</span>
  <div class="title-container__glomerulus">
    <img
      [strapiImgUrl]="
        glomerulus?.getPublicImageUrl(
          getServerUrl() + '/strapi-proxy/' + tenantId
        )
      "
      [extension]="'.webp'"
      width="86"
      height="45"
      alt="Wiedza | PWN"
    />
  </div>
</div>
