import { Component, Input, OnInit } from '@angular/core';
import {
  StrapiComponent,
  StrapiComponentTypeEnum,
  StrapiGalleryComponent,
  StrapiImageComponent,
  StrapiImageSizeEnum,
  StrapiParagraphComponent,
} from '@moose/pwn-cms-model/lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { ToastService } from '@shared/service/toast/toast.service';
import { Logger } from '@core';

const log = new Logger('ModalPopupsComponent');

@Component({
  selector: 'app-modal-popups',
  templateUrl: './modal-popups.component.html',
  styleUrls: [
    './modal-popups.component.scss',
    './modal-popups.component.mobile.scss',
  ],
})
export class ModalPopupsComponent implements OnInit {
  readonly tenantId = environment.tenantId;
  @Input()
  pageContent: (
    | StrapiGalleryComponent
    | StrapiImageComponent
    | StrapiParagraphComponent
  )[];

  @Input()
  components: StrapiComponent;
  @Input()
  displayName: string;

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;
  public imageFormat = StrapiImageSizeEnum.small;

  constructor(public modal: NgbModal, private toastService: ToastService) {}

  ngOnInit(): void {
    log.debug('Component init started..');
  }

  openModal(content: any): void {
    this.toastService.removeAllToasts();
    this.modal.open(content, { centered: true, windowClass: 'modal-window' });
    setTimeout(() => {
      const modalContent = document?.getElementsByClassName(
        'modal-scrollable-content'
      )[0];
      if (modalContent) {
        modalContent.scrollTo(null, 0);
      }
    }, 0);
  }
}
