<div class="logo-container">
  <a
    *ngIf="!!partner?.link"
    class="image-wrapper"
    href="{{ partner.link }}"
    target="_blank"
    rel="noopener noreferrer"
  >
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </a>
  <div *ngIf="!partner?.link" class="image-wrapper">
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </div>
</div>

<ng-template #image>
  <img
    [imgUrlWithSize]="imageFormat"
    [imgUrl]="partner.img.url"
    alt="{{ partner.name }}"
    class="logo-image"
    loading="lazy"
    width="120"
  />
</ng-template>
