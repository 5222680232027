import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { StrapiStaticTextComponent } from '@moose/pwn-cms-model/lib';

@Component({
  selector: 'app-static-text',
  templateUrl: './static-text.component.html',
  styleUrls: ['./static-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticTextComponent implements OnInit {
  @Input()
  component: StrapiStaticTextComponent;

  constructor() {}

  ngOnInit(): void {}
}
