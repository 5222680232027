import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ArticleService } from '@app/@shared/service/article/article.service';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';
import {
  SimpleArticle,
  SimpleCategory,
  StrapiTopArticlesComponent,
} from '@moose/pwn-cms-model/lib';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-article-list',
  templateUrl: './top-article-list.component.html',
  styleUrls: [
    './top-article-list.component.scss',
    './top-article-list.component.mobile.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopArticleListComponent implements OnInit {
  @Input()
  component: StrapiTopArticlesComponent;

  articleCategories = new Map<SimpleCategory, SimpleArticle[]>();
  categoriesLoaded = false;

  slugIdMap = new Map();
  categoriesSub: Subscription;

  constructor(
    private articleService: ArticleService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.component.categories.forEach((category) => {
      this.articleCategories.set(category, []);
    });

    this.articleService
      .getArticleListByTag(this.component.tag.name)
      .subscribe((articles: SimpleArticle[]) => {
        articles.forEach((article: SimpleArticle) => {
          this.component.categories.forEach((category) => {
            if (this.articleBelongsToCategory(article, category)) {
              this.articleCategories.get(category).push(article);
            }
          });
        });

        this.articleCategories.forEach((ar, key) => {
          ar.sort(
            (a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()
          );
          this.articleCategories.set(
            key,
            ar.slice(0, this.component.maxArticles)
          );
        });
        this.categoriesLoaded = true;
        this.cd.markForCheck();
      });
  }

  articleBelongsToCategory(
    article: SimpleArticle,
    category: SimpleCategory
  ): boolean {
    return !!article.categories.find((cat) => cat.id === category.id);
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
