import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/@shared';

import { ImageGalleryComponent } from '@app/image-gallery/image-gallery.component';

@NgModule({
  declarations: [ImageGalleryComponent],
  imports: [CommonModule, NgbModule, SharedModule],
  exports: [ImageGalleryComponent],
})
export class ImageGalleryModule {}
