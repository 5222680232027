import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Category,
  ElkDocumentObject,
  UrlUtils,
} from '@moose/pwn-cms-model/lib';
import { SearchBarResult } from '@shared/model/search-bar/search-bar-result';
import { SearchBarInputData } from '@shared/model/search-bar/search-bar-input-data';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CategoryService } from '@shared/service/category/category.service';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from '@shared/service/authentication/authentication.service';
import { UserProductService } from '@shared/service/user-product/user-product.service';
import { BreadcrumbService } from '@app/@shared/service/breadcrumb/breadcrumb.service';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: [
    './article-list.component.scss',
    './article-list.component.mobile.scss',
  ],
})
export class ArticleListComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  searchData: SearchBarInputData;

  @Input()
  searchType: string;

  @Input()
  showFavoriteLabel: boolean;

  userFavoriteArticlesIds: string[] = [];
  totalPages: number;
  itemsPerPage: number;
  totalElements: number;
  page: number | undefined;
  prevPage = 1;
  refreshFavorites = false;

  articles: ElkDocumentObject[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UrlUtils = UrlUtils;

  slugIdMap = new Map();

  categoriesColors: Map<string, string> = new Map<string, string>();

  categorySub: Subscription;
  userFavoriteArticleIds$: Subscription;

  isLoggedIn$: Observable<boolean>;

  constructor(
    private _sanitizer: DomSanitizer,
    private categoryService: CategoryService,
    private el: ElementRef,
    private breadcrumbService: BreadcrumbService,
    private authenticationService: AuthenticationService,
    private userProductService: UserProductService
  ) {}

  ngAfterViewInit(): void {
    this.breadcrumbService.newestList = 'Lista artykułow';
  }

  ngOnInit(): void {
    this.categorySub = this.categoryService
      .getCategories()
      .subscribe((res: Category[]) => {
        res.forEach((category: Category) => {
          this.categoriesColors.set(category.id, category.color);
          this.slugIdMap.set(
            category.id,
            this.categoryService.getSlugId(category)
          );
        });
        this.breadcrumbService.newestList = 'Lista artykułow';
      });
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;

    this.userFavoriteArticleIds$ = this.userProductService._userFavoriteArticlesIds.subscribe(
      (value) => {
        this.userFavoriteArticlesIds = value;
        this.breadcrumbService.newestList = 'Lista artykułow';
      }
    );
  }

  ngOnDestroy(): void {
    this.categorySub?.unsubscribe();
  }

  changePage(event: any, pageReset?: boolean): void {
    this.scrollToFirstInvalidControl();

    if (pageReset === true) {
      this.prevPage = event;
      this.page = this.prevPage === 1 ? undefined : this.prevPage;
      return;
    }
    if (event === this.prevPage) {
      return;
    }
    this.page = this.prevPage = event;
  }

  scrollToFirstInvalidControl() {
    setTimeout(() => {
      const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
        'app-search-bar'
      );

      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
      });
    });
  }

  getTopOffset(controlEl: HTMLElement): number {
    const offset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - offset;
  }

  getLeadHtml(article: ElkDocumentObject): SafeHtml | undefined {
    if (article.description != null) {
      return this._sanitizer.bypassSecurityTrustHtml(article.description);
    } else {
      return null;
    }
  }

  parseSearchResult(result: SearchBarResult): void {
    this.articles = result.data;
    this.totalElements = result.totalElements;
    this.totalPages = result.totalPages;
    this.itemsPerPage = result.itemsPerPage;
    this.breadcrumbService.newestList = 'Lista artykułow';
  }

  isFavorite(articleId: string): boolean {
    if (this.userFavoriteArticlesIds) {
      const index = this.userFavoriteArticlesIds.findIndex(
        (favoriteId) => favoriteId === articleId
      );
      return index > -1;
    } else {
      return false;
    }
  }

  addToFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .addArticleToFavorite(articleId)
        .subscribe((res) => {
          if (!this.userFavoriteArticlesIds) {
            this.userFavoriteArticlesIds = [];
          }
          if (
            res &&
            !this.userFavoriteArticlesIds.find(
              (favoriteId) => favoriteId === articleId
            )
          ) {
            this.userFavoriteArticlesIds.push(articleId);
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
        });
    }
  }

  removeFromFavorite(articleId: string) {
    if (this.isLoggedIn$) {
      this.userProductService
        .removeArticleFromFavorite(articleId)
        .subscribe((res) => {
          if (res && this.userFavoriteArticlesIds) {
            const productIndex = this.userFavoriteArticlesIds.indexOf(
              articleId
            );
            if (productIndex > -1) {
              this.userFavoriteArticlesIds.splice(productIndex, 1);
            }
          }
          this.userProductService.updateUserFavoriteArticlesIds(
            this.userFavoriteArticlesIds
          );
          this.userProductService.refreshFavorites.next(true);
        });
    }
  }
}
