import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  SimpleCategory,
  StrapiAdComponent,
  StrapiAdZone,
} from '@moose/pwn-cms-model/lib';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AdService } from '@shared/service/ad/ad.service';
import { Subscription } from 'rxjs';
import { PlatformService } from '@app/@shared/service/ssr/platform.service';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.scss', './ad.component.mobile.scss'],
})
export class AdComponent implements OnInit, OnDestroy {
  @Input()
  component: StrapiAdComponent;

  @Input()
  desktopAlign: 'left' | 'center' = 'center';

  subscription: Subscription;

  @Input()
  set categories(categories: SimpleCategory[]) {
    this.adService.setAdContext(categories);
  }

  mobileUrl: SafeHtml;
  desktopUrl: SafeHtml;
  showMobile = false;
  showDesktop = false;
  zoneLoaded = false;
  adZone: StrapiAdZone;

  constructor(
    private _sanitizer: DomSanitizer,
    private adService: AdService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (PlatformService.isBrowser) {
      console.log('AdComponent: OnInit: ' + this.component.id);

      this.adService.getAdZone(this.component.adZone.id).subscribe((az) => {
        this.adZone = az;
        this.refreshContext();
      });
      this.subscription = this.adService.getCtx().subscribe((ctx) => {
        this.refreshContext();
        setTimeout(() => {
          if (
            document.querySelector('.desktop.ad-container iframe')
              ?.clientHeight == 149
          ) {
            this.showDesktop = false;
          }
          if (
            document.querySelector('.mobile.ad-container iframe')
              ?.clientHeight == 149
          ) {
            this.showMobile = false;
          }
          this.zoneLoaded = true;
          this.cdr.detectChanges();
        }, 1000);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  refreshContext() {
    if (this.adZone) {
      const m = this.adService.getAdUrl(this.adZone, false);
      if (m == null || m === undefined || m.length === 0) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
        this.mobileUrl = this._sanitizer.bypassSecurityTrustHtml(m);
      }

      const d = this.adService.getAdUrl(this.adZone, true);
      if (d == null || d === undefined || d.length === 0) {
        this.showDesktop = false;
      } else {
        this.showDesktop = true;
        this.desktopUrl = this._sanitizer.bypassSecurityTrustHtml(d);
      }
    } else {
      this.zoneLoaded = false;
      console.log('ad zone not loaded yet... ');
    }
  }

  isBrowser(): boolean {
    return PlatformService.isBrowser;
  }
}
