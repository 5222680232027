<div class="footer-dynamic-item-desktop-container">
  <div class="footer-category-header">
    {{ _item.category.displayName || _item.category.name }}
  </div>
  <div class="footer-dynamic-item-link-container">
    <div *ngFor="let category of _item.categories">
      <a
        [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
        class="footer-dynamic-item-link"
      >
        {{ category.displayName || category.name }}
      </a>
    </div>
    <div class="footer-dynamic-item-button-container">
      <a
        class="footer-dynamic-item-button"
        [routerLink]="['/artykuly', slugIdMap.get(_item.category.id)]"
      >
        {{ getDisplayAllLabel() }}
      </a>
    </div>
  </div>
</div>

<div class="footer-dynamic-item-mobile-container">
  <div class="footer-category-header">
    {{ _item.category.displayName || _item.category.name }}
  </div>
  <div class="footer-dynamic-item-link-container">
    <div *ngFor="let category of _item.categories">
      <a
        [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
        class="footer-dynamic-item-link"
      >
        {{ category.displayName || category.name }}
      </a>
    </div>
    <div class="footer-dynamic-item-button-container">
      <a [routerLink]="['/artykuly', slugIdMap.get(_item.category.id)]">
        <div class="footer-dynamic-item-button">
          {{ getDisplayAllLabel() }}
        </div>
      </a>
    </div>
  </div>
</div>
