import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbDropdownModule,
  NgbModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ShellComponent } from './shell.component';
import { MainMenuModule } from '@app/shell/main-menu/main-menu.module';
import { SmallFooterModule } from '@app/shell/small-footer/small-footer.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { RegisterExistingComponent } from '@app/register-existing/register-existing.component';
import { SharedModule } from '@shared';
import { ShellRoutingModule } from './shell-routing.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    MainMenuModule,
    ShellRoutingModule,
    ReactiveFormsModule,
    InputTextModule,
    SmallFooterModule,
    NgbDropdownModule,
    NgbTypeaheadModule,
    TooltipModule,
    SharedModule,
  ],
  declarations: [ShellComponent, RegisterExistingComponent],
})
export class ShellModule {}
