<div
  class="content-container"
  [ngStyle]="{ 'min-height': viewLoaded ? 'unset' : '100vh' }"
>
  <div class="elements-container">
    <div class="elements">
      <app-hot-article
        *ngFor="let article of articleList; let index = index"
        [article]="article"
        [imageSize]="component?.imageSize"
        [readLabel]="component?.readLabel"
        [requirePreload]="component?.requirePreload && index === 0"
      >
      </app-hot-article>
    </div>
  </div>
</div>
