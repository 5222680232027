import { Component, Input, OnInit } from '@angular/core';
import { StrapiAttachmentListComponent } from '@moose/pwn-cms-model/lib';

@Component({
  selector: 'app-article-attachment-list',
  templateUrl: './article-attachment-list.component.html',
  styleUrls: ['./article-attachment-list.component.scss'],
})
export class ArticleAttachmentListComponent implements OnInit {
  @Input()
  attachments: StrapiAttachmentListComponent;

  constructor() {}

  ngOnInit(): void {}
}
