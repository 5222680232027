import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StrapiAuthor } from '@moose/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthorService {
  private readonly tenantId = environment.tenantId;
  timeout = false;

  constructor(private http: HttpClient, private state: TransferState) {}

  getAuthorById(authorId: string): Observable<StrapiAuthor> {
    return this.http.get<StrapiAuthor>(
      `/strapi-proxy/${this.tenantId}/author/byid/${authorId}`
    );
  }

  getAuthorsByBook(bookId: string): Observable<StrapiAuthor[]> {
    type T = StrapiAuthor[];
    const key = makeStateKey<T>('authorService_byBookId_' + bookId);
    const value: T = this.state.get<T>(key, null);
    if (value) {
      if (!this.timeout) {
        setTimeout(() => {
          this.state.set<T>(key, null);
          this.timeout = false;
        }, 2000);
        this.timeout = true;
      }
      return of(value);
    }
    return this.http
      .get<T>(`/strapi-proxy/${this.tenantId}/author/book/${bookId}`)
      .pipe(
        map((x) => {
          this.state.set<T>(key, x);
          return x;
        })
      );
  }
}
