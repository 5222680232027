<div
  class="footer-static-item-container"
  [style]="
    backgroundImageUrl && { background: 'url(' + backgroundImageUrl + ')' }
  "
>
  <div class="footer-category-header">{{ _item.title }}</div>
  <div class="footer-static-item-content">
    <div
      [innerHtml]="paragraphContent"
      class="footer-static-item-link ck-content"
    ></div>
  </div>
  <a
    *ngIf="_item.image && _item.imageExternalLink"
    href="{{ _item.imageExternalLink }}"
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      class="footer-static-item-image"
      [ngClass]="{ 'mobile-visible': _item.showImageOnMobile }"
      src="{{ imageUrl }}"
      loading="lazy"
    />
  </a>
  <a
    *ngIf="_item.image && _item.imageInternalLink"
    [routerLink]="[_item.imageInternalLink]"
  >
    <img
      class="footer-static-item-image"
      [ngClass]="{ 'mobile-visible': _item.showImageOnMobile }"
      src="{{ imageUrl }}"
      loading="lazy"
    />
  </a>
  <img
    *ngIf="_item.image && !(_item.imageExternalLink || _item.imageInternalLink)"
    class="footer-static-item-image"
    [ngClass]="{ 'mobile-visible': _item.showImageOnMobile }"
    src="{{ imageUrl }}"
    loading="lazy"
  />
  <div class="footer-static-desktop-item-button-container">
    <a
      class="footer-static-item-button"
      *ngIf="_item.buttonInternalUrl && !_item.buttonExternalUrl"
      [routerLink]="_item.buttonInternalUrl"
      >{{ _item.buttonText }}</a
    >
    <a
      class="footer-static-item-button"
      *ngIf="_item.buttonExternalUrl && !_item.buttonInternalUrl"
      target="_blank"
      rel="noopener noreferrer"
      href="{{ _item.buttonExternalUrl }}"
      >{{ _item.buttonText }}</a
    >
  </div>
  <div class="footer-static-mobile-item-button-container">
    <a
      class="footer-static-item-button"
      *ngIf="_item.buttonInternalUrl && !_item.buttonExternalUrl"
      [routerLink]="_item.buttonInternalUrl"
      >{{ _item.buttonText }}</a
    >
    <a
      class="footer-static-item-button"
      *ngIf="_item.buttonExternalUrl && !_item.buttonInternalUrl"
      target="_blank"
      rel="noopener noreferrer"
      href="{{ _item.buttonExternalUrl }}"
      >{{ _item.buttonText }}</a
    >
  </div>
</div>
