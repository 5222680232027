import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReadAlsoListComponent } from '@app/read-also-list/read-also-list.component';
import { ReadAlsoComponent } from './read-also/read-also.component';

@NgModule({
  declarations: [ReadAlsoListComponent, ReadAlsoComponent],
  imports: [CommonModule, RouterModule],
  exports: [ReadAlsoListComponent, ReadAlsoComponent],
})
export class ReadAlsoListModule {}
