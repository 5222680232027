import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subject, Subscription } from 'rxjs';
import {
  Category,
  StrapiImage,
  StrapiImageSizeEnum,
} from '@moose/pwn-cms-model/lib';

import { CategoryService } from '@shared/service/category/category.service';
import { environment } from '@env/environment';
import { MainMenuLink } from '@app/@shared/model/menu/main-menu-link';
import { Logger } from '@core';

const log = new Logger('CategoryDetailsComponent');

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss'],
  animations: [
    trigger('showHideTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CategoryDetailsComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  categoryId: string;

  @Input()
  mainMenuState: Subject<MainMenuLink>;

  category: Category;
  childCategories: Category[];

  selected = false;
  subscription: Subscription;

  slugIdMap = new Map();
  public imageFormat = StrapiImageSizeEnum.small;

  constructor(private categoryService: CategoryService) {
    this.childCategories = [];
  }

  ngOnDestroy(): void {
    log.debug('CategoryDetailsComponent: DESTROY ' + this.categoryId);
    this.subscription?.unsubscribe();
  }

  isMobile(): boolean {
    return window.innerWidth < 1000;
  }

  ngOnInit(): void {
    log.debug('CategoryDetailsComponent: INIT ' + this.categoryId);
    this.subscription = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((c) => {
          this.slugIdMap.set(c.id, this.categoryService.getSlugId(c));
          if (this.categoryId === c.id) {
            this.category = c;
          }
          if (this.categoryId === c.parentCategory) {
            if (this.childCategories.length < 4) {
              this.childCategories.push(c);
            }
          }
        });
      });
  }

  getBoxShadow(): string {
    if (this.selected) {
      return '0px +4px ' + this.category.color;
    } else {
      return 'none';
    }
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }

  getImageAlt(category: Category): string {
    if (!category.img) {
      return category.displayName;
    }
    if (
      this.isMobile() &&
      category.mobileImage &&
      category.mobileImage.alternativeText &&
      category.mobileImage.alternativeText.length
    ) {
      return category.mobileImage.alternativeText;
    }
    if (
      category.fullImg &&
      category.fullImg.alternativeText &&
      category.fullImg.alternativeText.length
    ) {
      return category.fullImg.alternativeText;
    }
    return category.displayName;
  }

  closeMenu() {
    this.childCategories.forEach((category) => {
      category.selected = false;
    });
    this.mainMenuState.next(null);
  }
}
