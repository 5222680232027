import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StrapiOccupationalGroupComponent } from '@moose/pwn-cms-model/lib';

export class PwzValidator {
  static VerifyPwz(): ValidatorFn {
    return (form: AbstractControl): { [key: string]: any } | null => {
      const pwzNumber: string = form.get('pwzNumber').value;
      const group = form.get('group').value;

      if (group.codeValidator) {
        if (!pwzNumber.match(group.codeValidator)) {
          form.get('pwzNumber').setErrors({ wrongPwzCode: true });
          return null;
        }
      }

      form.get('pwzNumber').setErrors(null);
      return null;
    };
  }
}
