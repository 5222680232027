import {
  SearchBarSortOptions,
  StrapiModelTypeEnum,
} from '@moose/pwn-cms-model/lib';

export class SearchBarInputData {
  categoryIds: string[];
  authorIds: string[];
  tagId: string;
  sourceId: string;
  type: string;
  voivodeship: string;
  city: string;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  modelType: StrapiModelTypeEnum[];
  expert: boolean | undefined;
  query: string;
  inArticleIds: string[];
  workplace: string;

  forcedSearchOption: SearchBarSortOptions | undefined;

  constructor(
    categoryIds?: string[],
    authorIds?: string[],
    modelType?: StrapiModelTypeEnum[],
    tagId?: string,
    sourceId?: string,
    type?: string,
    voivodeship?: string,
    city?: string,
    dateFrom?: Date | undefined,
    dateTo?: Date | undefined,
    expert?: boolean,
    query?: string,
    inArticleIds?: string[],
    workplace?: string
  ) {
    this.categoryIds = categoryIds;
    this.authorIds = authorIds;
    this.modelType = modelType;
    this.tagId = tagId;
    this.sourceId = sourceId;
    this.type = type;
    this.voivodeship = voivodeship;
    this.city = city;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.expert = expert;
    this.query = query;
    this.inArticleIds = inArticleIds;
    this.workplace = workplace;
  }
}
