<ng-container
  *ngFor="let component of components"
  [ngSwitch]="component.__component"
>
  <app-ad-video
    *ngSwitchCase="componentTypes.AdVideo"
    [component]="component"
  ></app-ad-video>
</ng-container>
<div *ngFor="let component of _components" [ngSwitch]="component.__component">
  <app-partner-list
    *ngSwitchCase="componentTypes.Partners"
    [component]="component"
    [isFull]="true"
  ></app-partner-list>
  <app-newsletter
    *ngSwitchCase="componentTypes.Newsletter"
    [component]="component"
  ></app-newsletter>
  <app-bookstore
    *ngSwitchCase="componentTypes.Bookstore"
    [component]="component"
    [categoryId]="category?.id"
  ></app-bookstore>
  <app-ad
    *ngSwitchCase="componentTypes.Ad"
    [component]="component"
    [categories]="categories"
  ></app-ad>
  <app-social-media
    *ngSwitchCase="componentTypes.SocialMedia"
    [component]="component"
  ></app-social-media>
</div>
