<div
  *ngIf="partners && partners.length > 0 && isBrowserDisplay"
  class="content-container"
  [ngClass]="{
    'content-container-full': isFull,
    'content-container-main': isMain
  }"
>
  <h1>{{ component.title }}</h1>
  <div [ngSwitch]="component.displayType">
    <div
      class="partner-list-elements-full"
      *ngSwitchCase="displayTypeEnum.full"
    >
      <app-partner-logo
        *ngFor="let partner of partners"
        [partner]="partner"
      ></app-partner-logo>
    </div>

    <ng-container *ngSwitchCase="displayTypeEnum.scrollHorizontal">
      <div class="partner-list-desktop">
        <p-carousel
          [value]="partners"
          [numVisible]="5"
          [numScroll]="isMain ? 3 : 5"
          [autoplayInterval]="3000"
          [circular]="false"
          verticalViewPortHeight
          styleClass="p-carousel p-widget p-carousel-horizontal"
        >
          <!-- property `verticalViewPortHeight` is required to allow modification of it in CSS ...hmmm...JUST PRIMENG!!!-->
          <!-- property styleClass is required to fix bug on build dev mode ...PRIMENG ONCE AGAIN!!! -->
          <ng-template let-partner pTemplate="item">
            <app-partner-logo [partner]="partner"></app-partner-logo>
          </ng-template>
        </p-carousel>
      </div>

      <div class="partner-list-mobile">
        <p-carousel
          [value]="partners"
          [numVisible]="3"
          [numScroll]="3"
          [autoplayInterval]="3000"
          [circular]="false"
          [orientation]="displayMode"
          verticalViewPortHeight
          styleClass="p-carousel p-widget p-carousel-horizontal p-carousel-vertical"
        >
          <ng-template let-partner pTemplate="item">
            <app-partner-logo [partner]="partner"></app-partner-logo>
          </ng-template>
        </p-carousel>
      </div>
    </ng-container>
  </div>
</div>
