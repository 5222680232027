import { Injectable } from '@angular/core';
import { PlatformService } from '@shared/service/ssr/platform.service';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  constructor(private readonly platformService: PlatformService) {}

  scrollTo(x: number, y: number): void {
    if (PlatformService.isBrowser) {
      window.scroll(x, y);
    }
  }
}
