import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PwnUserWithTokens } from '@moose/pwn-cms-model/lib/model/user/pwn-user-with-tokens';
import { ConfigurationService } from '../configuration/configuration.service';
import { flatMap } from 'rxjs/operators';
import { PwnAgreements, PwnSubscribe } from '@moose/pwn-cms-model/lib';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  constructor(
    private http: HttpClient,
    private configurationService: ConfigurationService
  ) {}

  subscribeToNewsletter(
    form: any,
    agreements: PwnAgreements,
    redirectUrl?: string
  ): Observable<PwnUserWithTokens> {
    return this.configurationService.getConfiguration().pipe(
      flatMap((configuration) => {
        const body: PwnSubscribe = {
          tenantId: configuration.tenantId,
          email: form.email,
          agreements,
        };
        if (redirectUrl) {
          body.newsletterRedirectUrl = redirectUrl;
        }
        return this.http.post<PwnUserWithTokens>('/pwn-user/newsletter', body);
      })
    );
  }
}
