import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  public haveLinksBeenShown = false;

  private _links = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) {}

  reloadLinks(): void {
    // TODO Pass OAuth access token in order to obtain proper links value from the server
    this.http.get<string>('/pwn-user/links').subscribe((value) => {
      this._links.next(value);
    });
  }

  getLinks(): Observable<string | null> {
    return this._links;
  }
}
