import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta, Title, TransferState } from '@angular/platform-browser';
import {
  StrapiConfiguration,
  StrapiImage,
  StrapiImageClass,
} from '@moose/pwn-cms-model/lib';
import { MetaOgType } from '@shared/model/meta/meta-og-type.enum';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../configuration/configuration.service';
import { NavigationStart, Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import { StrapiSeoComponent } from '@app/@shared/model/meta/strapi-seo-component.interface';
@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  private readonly tenantId: string = environment.tenantId;
  private readonly serverUrl: string = environment.serverUrl;
  private readonly isProduction: boolean = environment.production;
  private defaultConfigTitle = '';
  private defaultConfigDesc = '';
  private defaultConfigLogoUrl = '';
  private defaultConfigFaviconUrl = '';

  private META_MAP = [
    {
      addr: '/',
      title: 'Portal dla psychologów, psychoterapeutów i studentów',
      desc:
        'Portal Wydawnictwa Naukowego PWN dla psychologów, psychoterapeutów i studentów psychologii. Źródło naukowej wiedzy, rzetelnych informacji i ciekawych wydarzeń',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    {
      addr: '/zostan-autorem',
      title: 'Zostań naszym autorem – portal Psychologia.PWN.pl',
      desc:
        'Chcesz opublikować swój tekst w naszym portalu? Skontaktuj się z nami i dołącz do grona autorów Wydawnictwa Naukowego PWN',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii, autor',
    },
    {
      addr: '/artykuly',
      title: 'Artykuły dla psychologów, psychoterapeutów i studentów',
      desc:
        'Źródło merytorycznej wiedzy z psychologii biznesu, klinicznej, sądowej, a także na temat mózgu, rodziny, relacji, metodologii, statystyki. Dowiedz się więcej!',
      keywords:
        'psychologia biznesu, psychologia sądowa, psychologia kliniczna, mózg, rodzina i relacje, badania w psychologii',
    },
    {
      addr: '/aktualnosci',
      title: 'Nowości z psychologii i psychoterapii - w Polsce i na świecie',
      desc:
        'Najnowsze informacje przydatne dla psychologów, psychoterapeutów i osób studiujących na tych kierunkach. Sprawdź aktualności!',
      keywords:
        'newsy z psychologii, wyniki badań, badania naukowe, aktualności w psychologii, nowe terapie, nowości',
    },
    {
      addr: '/logowanie',
      title: 'Strona logowania',
      desc:
        'Zaloguj się aby zyskać dostęp do artykułów merytorycznych dla psychologów i psychoterapeutów',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    {
      addr: '/wyniki-wyszukiwania',
      title: 'Źródło wiedzy, aktualności i wydarzeń – Psychologia.PWN.pl',
      desc:
        'Wyniki wyszukiwania w artykułach merytorycznych i aktualnościach z dziedziny psychologii oraz psychoterapii',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    {
      addr: '/nasi-autorzy',
      title: 'Kto dla nas pisze? – portal Psychologia.PWN.pl ',
      desc:
        'Poznaj liczne grono autorów współpracujących z Wydawnictwem Naukowym PWN.',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    // {
    //   addr: '/artykuly/artykuly-5f33a473643341036688beac',
    //   title: 'Artykuły cenionych ekspertów medycznych | Nursing.com.pl',
    //   desc:
    //     'Artykuły naukowe i specjalistyczne dla pielęgniarek i położnych poparte doświadczeniem wybitnych ekspertów. Aktualna i rzetelna wiedza dla każdej specjalizacji.',
    //   keywords: 'nursing, pielęgniarka, położna, wiedza, wiedza medyczne',
    // },
    // {
    //   addr: '/artykuly/pielegniarstwo-internistyczne-5f33a474643341036688beb6',
    //   title: 'Wiedza z zakresu pielęgniarstwa internistycznego | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla pielęgniarstwa internistycznego. Aktualna wiedza, procedury i praktyczne informacje przydatne w codziennej pracy pielęgniarki.',
    //   keywords: 'nursing, pielęgniarka, położna, artykuły, wiedza',
    // },
    // {
    //   addr: '/artykuly/pielegniarstwo-operacyjne-5f33a474643341036688becb',
    //   title: 'Wiedza z zakresu pielęgniarstwa operacyjnego | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla pielęgniarstwa operacyjnego. Aktualna wiedza i praktyczne informacje przydatne w codziennej pracy pielęgniarki operacyjnej.',
    //   keywords: 'nursing, pielęgniarstwo operacyjne, operacje, blok operacyjny',
    // },
    // {
    //   addr: '/artykuly/pielegniarstwo-ogolne-5f33a473643341036688beb4',
    //   title: 'Wiedza z zakresu pielęgniarstwa ogólnego | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla pielęgniarstwa ogólnego. Aktualna wiedza, felietony i praktyczne informacje przydatne w codziennej pracy każdej pielęgniarki.',
    //   keywords: 'nursing, pielęgniarstwo ogólne, praca pielęgniarki, zawód pielęgniarki',
    // },
    {
      addr: '/wydarzenia',
      title: 'Najważniejsze wydarzenia dla psychologów i psychoterapeutów ',
      desc:
        'Najważniejsze i najciekawsze konferencje, sprawdzone kursy, rekomendowane szkolenia i webinaria dla psychologów i psychoterapeutów. 👉 Dowiedz się więcej!',
      keywords:
        'webinaria psychologiczne, szkolenia dla psychologów, kursy dla psychoterapeutów, konferencje psychologia, kształcenie psychologów',
    },
    // {
    //   addr: '/artykuly/poloznictwo-5f33a473643341036688beb0',
    //   title: 'Artykuły specjalistyczne z dziedziny położnictwa | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla położnych. Aktualna wiedza z zakresu ciąży i porodu, procedury, praktyczne informacje przydatne w codziennej pracy każdej położnej.',
    //   keywords: 'nursing, położnictwo, położna, praca położnej',
    // },
    // {
    //   addr: '/artykuly/pielegniarstwo-pediatryczne-5f33a474643341036688beb7',
    //   title: 'Wiedza z zakresu pielęgniarstwa pediatrycznego | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla pielęgniarstwa pediatrycznego. Aktualna wiedza, procedury i praktyczne informacje przydatne w opiece pielęgniarskiej nad dziećmi.',
    //   keywords: 'nursing, pielęgniarstwo pediatryczne, pediatria, opieka pediatryczna',
    // },
    // {
    //   addr: '/ksiazki/5f33a473643341036688bead',
    //   title: 'Publikacje dla pielęgniarek i położnych | Nursing.com.pl',
    //   desc:
    //     'Polecane książki Wydawnictwa Medycznego PZWL z zakresu pielęgniarstwa specjalistycznego i położnictwa. Poradniki dla pacjentów, publikacje przydatne w zawodzie.',
    //   keywords: 'nursing, PZWL, książki medyczne, książki dla pielęgniarek, książki dla położnych',
    // },
    // {
    //   addr: '/artykuly/prawo-5fa1097e90d1843916077a70',
    //   title: 'Regulacja prawne. Zawód pielęgniarki i położnej | Nursing.com.pl',
    //   desc:
    //     'Zawód pielęgniarki i położnej. Zmiany prawne, rozporządzenia, ustawy w zakresie pracy, kształcenia, wynagrodzenia, świadczeń opieki zdrowotnej, ochrony zdrowia.',
    //   keywords:
    //     'nursing, prawo wykonywania zawodu, zawód pielęgniarki, zawód położnej, kształcenie pielęgniarek, kształcenie położnych',
    // },
    {
      addr: '/o-nas',
      title: 'PSYCHOLOGIA PWN - dla psychologów i psychoterapeutów',
      desc:
        'Ciekawe i podane w przystępny sposób informacje z psychologii klinicznej, poznawczej, biznesu, sądowej, zakresu funkcjonowania mózgu oraz relacji i rodziny.',
      keywords:
        'nportal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    {
      addr: '/kontakt',
      title: 'Masz pytanie? Napisz. Kontakt z redakcją | Psychologia.PWN.pl',
      desc: 'Skontaktuj się z nami 📧',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    // {
    //   addr: '/artykuly/pielegniarstwo-chirurgiczne-5f33a473643341036688beb5',
    //   title: 'Wiedza z zakresu pielęgniarstwa chirurgicznego | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły dla pielęgniarstwa chirurgicznego. Aktualna wiedza i praktyczne informacje z zakresu chirurgii, przydatne w w zawodzie pielęgniarki.',
    //   keywords: 'nursing, pielęgniarstwo chirurgiczne, chirurgia, zabiegi chirurgiczne',
    // },
    // {
    //   addr: '/artykuly/aktualnosci-5f33a473643341036688beab',
    //   title: 'Aktualne informacje ze świata medycyny | Nursing.com.pl',
    //   desc:
    //     'Zawsze aktualne informacje ze świata medycyny, najnowsze rozwiązania, standardy i obecnie stosowane technologie. Wiadomości dla pielęgniarek i położnych.',
    //   keywords: 'nursing, aktualności, medycyna, standardy medyczne, praca pielęgniarki, praca położnej',
    // },
    // {
    //   addr: '/reset-hasla',
    //   title: 'Zresetuj hasło i ciesz się fachową wiedzą | Nursing.com.pl',
    //   desc:
    //     'Nie pamiętasz hasła do swojego konta na portalu Nursing? Zresetuj hasło i ponownie czytaj artykuły eksperckie oraz aktualności dla pielęgniarek i położnych.',
    //   keywords: 'nursing, portal dla pielęgniarek, portal dla położnych',
    // },
    {
      addr: '/rejestracja',
      title: 'Załóż konto w portalu Psychologia.pwn.pl',
      desc:
        'Zyskaj darmowy dostęp do specjalistycznej wiedzy psychologicznej. Po rejestracji przeczytasz wybrane, merytoryczne artykuły dla psychologów i psychoterapeutów.',
      keywords:
        'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii',
    },
    // {
    //   addr: '/artykuly/pielegniarstwo-5f33a473643341036688beb1',
    //   title: 'Specjalistyczna wiedza z zakresu pielęgniarstwa | Nursing.com.pl',
    //   desc:
    //     'Specjalistyczne artykuły z dziedziny pielęgniarstwa. Aktualna i rzetelna wiedza dla każdej specjalizacji, poparta doświadczeniem wybitnych ekspertów.',
    //   keywords: 'nursing, pielęgniarka, pielęgniarstwo, nauka zawodu, zawód pielęgniarki, kształcenie pielęgniarki',
    // },
    // {
    //   addr: '/artykul/polozne-w-social-mediach-6010269b6ce92c43e2f8fe19',
    //   title: 'Położne w social mediach | Nursing.com.pl',
    //   desc:
    //     'Media społecznościowe wyzwaniem dla położnych. Jak znaleźć czas na studia, pracę i działalność w sieci? Jak dzielić się wiedzą medyczną w social mediach? ',
    //   keywords: 'nursing, położna, praca położnej, media społecznościowe, social media',
    // },
    // {
    //   addr: '/artykul/specyficzne-problemy-zwiazane-z-operacjami-w-ortopedii-i-traumatologii-6001a2cb6ce92c2974f8fdd7',
    //   title: 'Operacje w ortopedii i traumatologii - ułożenie pacjenta',
    //   desc:
    //     'Prawidłowe ułożenie pacjenta do operacji ortopedycznej/traumatologicznej. Zastosowanie opaski uciskowej i "bezpieczny" czas utrzymywania niedokrwienia.',
    //   keywords:
    //     'nursing, operacje, ortopedia, traumatologia, operacje ortopedyczne, operacje traumatologiczne, ułożenie pacjenta, opaska uciskowa',
    // },
  ];

  private static removeHtml(stringWithHtml: string): string {
    return stringWithHtml.replace(/(<([^>]+)>)/gi, ' ');
  }

  // source: https://github.com/samvloeberghs/kwerri-oss/blob/master/projects/ngx-seo/src/lib/seo-social-share/seo-social-share.service.ts

  constructor(
    private readonly metaService: Meta,
    private readonly titleService: Title,
    private readonly configService: ConfigurationService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private state: TransferState,
    private router: Router
  ) {
    // TODO: transfer state
    // TODO: change to config servcie (?)
    this.configService.getConfiguration().subscribe((strapiConfig) => {
      if (!strapiConfig) {
        return;
      }

      this.defaultConfigTitle = strapiConfig.title;
      this.defaultConfigDesc = strapiConfig.description;
      if (this.isProduction) {
        if (strapiConfig.logo && strapiConfig.logo.url) {
          this.defaultConfigLogoUrl = new StrapiImageClass({
            url: strapiConfig.logo.url,
            provider: 'aws-s3',
          } as StrapiImage).getPublicImageUrl(
            this.serverUrl + '/strapi-proxy/' + this.tenantId
          );
        }
        if (strapiConfig.favicon && strapiConfig.favicon.url) {
          this.defaultConfigFaviconUrl = new StrapiImageClass({
            url: strapiConfig.favicon.url,
            provider: 'aws-s3',
          } as StrapiImage).getPublicImageUrl(
            this.serverUrl + '/strapi-proxy/' + this.tenantId
          );
        }
      } else {
        if (strapiConfig.logo && strapiConfig.logo.url) {
          this.defaultConfigLogoUrl = new StrapiImageClass({
            url: strapiConfig.logo.url,
            provider: 'local',
          } as StrapiImage).getPublicImageUrl(
            this.serverUrl + '/strapi-proxy/' + this.tenantId
          );
        }
        if (strapiConfig.favicon && strapiConfig.favicon.url) {
          this.defaultConfigFaviconUrl = new StrapiImageClass({
            url: strapiConfig.favicon.url,
            provider: 'local',
          } as StrapiImage).getPublicImageUrl(
            this.serverUrl + '/strapi-proxy/' + this.tenantId
          );
        }
      }
      if (document) {
        const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href =
          this.serverUrl +
          '/strapi-proxy/' +
          this.tenantId +
          '/image/' +
          this.defaultConfigFaviconUrl.split('/').pop();
      }
      this.setDefaultMetaData();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const ns: NavigationStart = event;
        if (ns.url && ns.url.length > 0) {
          this.createOrUpdateCanonical(ns.url);
          const meta = this.META_MAP.find((m) => m.addr === ns.url);
          if (meta) {
            this.createOrUpdateDescription(meta.desc);
            this.createOrUpdateKeywords(meta.keywords);
            this.createOrUpdateTitle(meta.title);
          }
        }
      }
    });
  }

  public setDefaultMetaData() {
    // TODO: favicon
    // TODO: site url from router
    this.setData(
      this.defaultConfigTitle ??
        'Portal dla psychologów, psychoterapeutów i studentów',
      this.defaultConfigDesc ??
        'Portal Wydawnictwa Naukowego PWN dla psychologów, psychoterapeutów i studentów psychologii. Źródło naukowej wiedzy, rzetelnych informacji i ciekawych wydarzeń',
      this.defaultConfigLogoUrl ?? '',
      '',
      MetaOgType.Website,
      'portal psychologiczny, kształcenie psychoterapeuty, zawód psychologa, dziedziny psychologii, nurty psychoterapii'
    );
  }

  public setData(
    title: string,
    description: string,
    image: string,
    url: string,
    type: MetaOgType,
    keywords: string
  ) {
    this.createOrUpdateTitle(title);
    this.createOrUpdateDescription(description);
    this.createOrUpdateImage(image);
    this.createOrUpdateUrl(url);
    this.createOrUpdateType(type);
    this.createOrUpdateKeywords(keywords);
  }

  public removeData() {
    this.createOrUpdateTitle('');
    this.createOrUpdateDescription('');
    this.createOrUpdateImage('');
    this.createOrUpdateUrl('');
    this.createOrUpdateType(MetaOgType.Website);
  }

  private createOrUpdateRobots(robots: string) {
    robots = MetaDataService.removeHtml(robots);
    this.metaService.updateTag({ name: 'robots', content: robots });
  }

  private createOrUpdateTitle(title: string = ''): void {
    title = MetaDataService.removeHtml(title);
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'twitter:title', content: title });
    this.metaService.updateTag({ name: 'twitter:image:alt', content: title });
    this.metaService.updateTag({ property: 'og:image:alt', content: title });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ name: 'title', content: title });
    this.metaService.updateTag({ itemprop: 'name', content: title });
  }

  private createOrUpdateDescription(description: string): void {
    description = MetaDataService.removeHtml(description);
    this.metaService.updateTag({
      name: 'twitter:description',
      content: description,
    });
    this.metaService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({
      itemprop: 'description',
      content: description,
    });
  }

  private createOrUpdateImage(image: string): void {
    let imageNew =
      environment.serverUrl +
      '/strapi-proxy/' +
      environment.tenantId +
      '/' +
      image;
    this.metaService.updateTag({ name: 'twitter:image', content: imageNew });
    this.metaService.updateTag({ itemprop: 'image', content: imageNew });
    this.metaService.updateTag({ property: 'og:image', content: imageNew });
    this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
  }

  private createOrUpdateUrl(url: string): void {
    this.metaService.updateTag({ property: 'og:url', content: url });
  }

  private createOrUpdateType(type: MetaOgType) {
    this.metaService.updateTag({ property: 'og:type', content: type });
  }

  private createOrUpdateKeywords(keywords: string) {
    this.metaService.updateTag({ name: 'keywords', content: keywords });
  }

  public createOrUpdateCanonical(url: string | undefined) {
    const oldlink: HTMLLinkElement = this.document.querySelector(
      'link[rel=canonical]'
    );

    if (oldlink) {
      oldlink.remove();
    }

    if (url) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);
      link.setAttribute('href', environment.serverUrl + url);
    }
  }

  public createOrUpdateProductRetailerPartNo(id: string) {
    this.metaService.updateTag({
      property: 'product:retailer_part_no',
      content: id,
    });
  }

  public setDataFromStrapiSeoComponent(data: any) {
    if (!data || !data.seo) {
      return;
    }
    const componentData: StrapiSeoComponent = data.seo;
    if (componentData.overrideCanonicalUrl) {
      this.createOrUpdateCanonical(componentData.overrideCanonicalUrl);
    }
    if (componentData.metaTitle) {
      this.createOrUpdateTitle(componentData.metaTitle);
    }
    if (componentData.metaDescription) {
      this.createOrUpdateDescription(componentData.metaDescription);
    }
    if (componentData.image) {
      this.createOrUpdateImage(componentData.image);
    }
    if (componentData.metaKeywords) {
      this.createOrUpdateKeywords(componentData.metaKeywords);
    }
    if (componentData.robotsFollow && componentData.robotsIndex) {
      const robots = `${componentData.robotsIndex}, ${componentData.robotsFollow}`;
      this.createOrUpdateRobots(robots);
    }
  }
}
