import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmallFooterComponent } from '@app/shell/small-footer/small-footer.component';
import { RouterModule } from '@angular/router';
import { ModalPopupsComponent } from '@app/shell/small-footer/modal-popups/modal-popups.component';
import { ImageGalleryModule } from '@app/image-gallery/image-gallery.module';
import { ParagraphModule } from '@app/paragraph/paragraph.module';
import { SharedModule } from '@app/@shared';

@NgModule({
  declarations: [SmallFooterComponent, ModalPopupsComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageGalleryModule,
    ParagraphModule,
    SharedModule,
  ],
  exports: [SmallFooterComponent, ModalPopupsComponent],
})
export class SmallFooterModule {}
