import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Logger } from '@core';

const log = new Logger('RouteLoggerService');

@Injectable({
  providedIn: 'root',
})
export class RouteLoggerService {
  constructor(private readonly router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        log.debug('Navigation started: ' + event.url);
      } else if (event instanceof NavigationError) {
        log.debug('Navigation error: ' + event.url);
      } else if (event instanceof NavigationCancel) {
        log.debug('Navigation cancel: ' + event.url);
      } else if (event instanceof NavigationEnd) {
        log.debug('Navigation end: ' + event.url);
      }
    });
  }
}
