import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SimpleArticle, UrlUtils } from '@moose/pwn-cms-model/lib';
import { Subscription } from 'rxjs';
import { CategoryService } from '@shared/service/category/category.service';

@Component({
  selector: 'app-read-also',
  templateUrl: './read-also.component.html',
  styleUrls: [
    './read-also.component.scss',
    './read-also.component.mobile.scss',
  ],
})
export class ReadAlsoComponent implements OnInit, OnDestroy {
  @Input() article: SimpleArticle;

  @Input() readMore: string;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  getSlugId(id: string, title: string): string {
    return UrlUtils.toSlugId(title, id);
  }

  ngOnDestroy(): void {
    this.categoriesSub?.unsubscribe();
  }
}
