import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookstoreComponent } from '@app/bookstore/bookstore.component';
import { CarouselModule } from 'primeng/carousel';
import { SharedModule } from '@shared';

@NgModule({
  declarations: [BookstoreComponent],
  imports: [CommonModule, CarouselModule, SharedModule],
  exports: [BookstoreComponent],
})
export class BookstoreModule {}
