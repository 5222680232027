import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];
  isToasts: boolean;

  show(textOrTpl: string | TemplateRef<any>, options: any = {}, id?: string) {
    this.isToasts = true;
    if (!this.toasts.find((toast) => !!toast.id && toast.id === id)) {
      this.toasts.push({ id, textOrTpl, ...options });
    }
  }

  replaceToast(
    textOrTpl: string | TemplateRef<any>,
    options: any = {},
    id?: string
  ): void {
    this.isToasts = true;
    this.toasts = [{ id, textOrTpl, ...options }];
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.isToasts = false;
  }

  removeAllToasts(): void {
    this.toasts = [];
    this.isToasts = false;
  }
}
