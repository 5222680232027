<hr [ngStyle]="{ background: (article?.categories)[0]?.color }" />
<div class="desktop article-all">
  <div class="article-content">
    <div class="article-top-line">
      <div>
        <span *ngFor="let category of article.categories">
          <a
            [routerLink]="['/artykuly', slugIdMap.get(category.id)]"
            class="category"
            [ngStyle]="{ color: category.color }"
          >
            {{ category.name | uppercase }}
          </a>
        </span>
      </div>
    </div>

    <a
      [href]="'/artykul/' + getSlugId(article.id, article.title)"
      class="p-title"
      [innerHtml]="article.title"
    ></a>
    <div class="bottom-container">
      <span class="p-date">{{ article.date | date: 'dd.MM.yyyy' }}</span>
      <a
        [href]="'/artykul/' + getSlugId(article.id, article.title)"
        class="read-more"
      >
        {{ readMore }}
        <span role="presentation" class="arrow_right material-icons">
          trending_flat
        </span>
      </a>
    </div>
  </div>
</div>
