import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesComponent } from '@app/articles/articles.component';
import { ArticlesRoutingModule } from '@app/articles/articles-routing.module';
import { ArticleListComponent } from './article-list/article-list.component';
import { AdModule } from '@app/ad/ad.module';
import { CategoryListComponent } from '@app/articles/category-list/category-list.component';
import { PartnerListModule } from '@app/partner-list/partner-list.module';
import { HeaderComponent } from './header/header.component';
import { SearchBarComponent } from '@app/search-bar/search-bar.component';
import {
  NgbDropdownModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { TreeModule } from 'primeng/tree';
import { LargeFooterModule } from '@app/large-footer/large-footer.module';
import { BookstoreModule } from '@app/bookstore/bookstore.module';
import { NewsletterModule } from '@app/newsletter/newsletter.module';
import { ArticleModule } from '@app/article/article.module';
import { SharedModule } from '@app/@shared';
import { AdVideoModule } from '@app/ad-video/ad-video.module';

@NgModule({
  declarations: [
    ArticlesComponent,
    ArticleListComponent,
    CategoryListComponent,
    CategoryListComponent,
    HeaderComponent,
    SearchBarComponent,
  ],
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    AdModule,
    AdVideoModule,
    PartnerListModule,
    TreeModule,
    NgbPaginationModule,
    FormsModule,
    NgbDropdownModule,
    LargeFooterModule,
    BookstoreModule,
    NewsletterModule,
    ArticleModule,
    SharedModule,
  ],
  exports: [
    HeaderComponent,
    CategoryListComponent,
    SearchBarComponent,
    ArticleListComponent,
  ],
})
export class ArticlesModule {}
