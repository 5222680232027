import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CategoryService } from '@shared/service/category/category.service';
import {
  Category,
  CategoryTree,
  StrapiCategoryListComponent,
} from '@moose/pwn-cms-model/lib';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: [
    './category-list.component.scss',
    './category-list.component.mobile.scss',
  ],
})
export class CategoryListComponent implements OnInit, OnDestroy {
  _categoryId: string;
  _component: StrapiCategoryListComponent;
  categoryTree: CategoryTree;
  categories: Category[];
  treeNodes: TreeNode[];
  flatTreeNodes: TreeNode[];
  categoryTreeSubscription: Subscription;

  selectedNodes: TreeNode[] = [];

  showMoreCategories: string[] = [];

  modalRef: NgbModalRef;

  slugIdMap = new Map();
  categoriesSub: Subscription;

  @Input()
  set component(component: StrapiCategoryListComponent) {
    this._component = component;
    if (component != null && this.selectedNodes.length > 0) {
      this.selectedNodes.forEach((node) => {
        if (
          node.parent &&
          node.parent.children.indexOf(node) >= component.subCategoriesToDisplay
        ) {
          this.changeVisibility(node.data);
        }
      });
    }
  }

  @Input()
  set categoryId(categoryId: string) {
    this._categoryId = categoryId;

    this.categoryTreeSubscription = this.categoryService
      .getTreeNodes(categoryId)
      .subscribe((treeNodes: TreeNode[]) => {
        if (treeNodes != null) {
          this.treeNodes = treeNodes;
          if (
            this.treeNodes.length > 0 &&
            categoryId != null &&
            categoryId.length > 0
          ) {
            this.selectedNodes = [];
            const array: TreeNode[] = [];
            this.flatTreeNodes = [];
            this.treeNodes.forEach((treeNode) => {
              if (
                treeNode.data.id === categoryId ||
                this.isAncestor(categoryId, treeNode)
              ) {
                this.selectedNodes.push(treeNode);
                this.flattenTree(treeNode, array);
              } else {
                treeNode.expanded = treeNode.data.alwaysExpanded;
              }
              this.flattenTree(treeNode, this.flatTreeNodes);
            });
            const node = array.find(
              (currentNode: TreeNode) => currentNode.data.id === categoryId
            );
            this.selectedNodes.push(node);
            this.findParent(node, array);
            this.selectedNodes.concat(array);
          }
        }
      });
  }

  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private modal: NgbModal
  ) {}

  ngOnDestroy(): void {
    this.categoryTreeSubscription?.unsubscribe();
    this.categoriesSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  onNodeSelect() {
    const node = this.selectedNodes[0];
    if (
      this.showMore(node.data) ||
      node.parent == null ||
      node.parent.children.indexOf(node) <
        this._component.subCategoriesToDisplay
    ) {
      this.redirectToCategory(this.selectedNodes[0].data.id);
    }
  }

  redirectToCategory(category: string) {
    this.router.navigate([
      this.router.url.split('/')[1],
      this.slugIdMap.get(category),
    ]);
    this.modalRef?.close();
  }

  changeVisibility(category: Category | null) {
    if (this.showMoreCategories.includes(category.parentCategory)) {
      const index = this.showMoreCategories.indexOf(category.parentCategory, 0);
      if (index > -1) {
        this.showMoreCategories.splice(index, 1);
      }
    } else {
      this.showMoreCategories.push(category.parentCategory);
    }
  }

  showMore(category: Category): boolean {
    return (
      category.parentCategory &&
      this.showMoreCategories.includes(category.parentCategory)
    );
  }

  openCategoryList(content: any): void {
    this.modalRef = this.modal.open(content, {
      centered: true,
      windowClass: 'category-list-mobile-content',
      keyboard: false,
      backdrop: 'static',
    });

    this.modalRef.result.then();
  }

  showElement(element: Element): boolean {
    const pTreeNode = element.closest('p-treenode');
    pTreeNode.setAttribute('style', 'display: inherit');

    return true;
  }

  hideElement(element: Element): boolean {
    const pTreeNode = element.closest('p-treenode');
    pTreeNode.setAttribute('style', 'display: none');

    return true;
  }

  private isAncestor(categoryId: string, treeNode: TreeNode): boolean {
    return this.find(categoryId, treeNode).length > 0;
  }

  private find(categoryId: string, currentTreeNode: TreeNode): TreeNode[] {
    const result: TreeNode[] = [];

    if (currentTreeNode.data.id === categoryId) {
      currentTreeNode.expanded = true;
      result.push(currentTreeNode);
    }

    if (currentTreeNode.children) {
      result.push(
        ...currentTreeNode.children.flatMap((treeNode) =>
          this.find(categoryId, treeNode)
        )
      );
    }
    return result;
  }

  private flattenTree(node: TreeNode, array: TreeNode[]): TreeNode[] {
    array.push(node);

    if (node.children) {
      node.children.forEach((childNode) => {
        this.flattenTree(childNode, array);
      });
    }
    return array;
  }

  private findParent(node: TreeNode, array: TreeNode[]) {
    node.expanded = true;
    array.push(node);
    if (node.parent != null) {
      this.findParent(node.parent, array);
    }
  }
}
