import { Component, Input, OnInit } from '@angular/core';
import { StrapiCustomHtmlComponent } from '@moose/pwn-cms-model/lib';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-html',
  templateUrl: './custom-html.component.html',
  styleUrls: [
    './custom-html.component.scss',
    './custom-html.component.mobile.scss',
  ],
})
export class CustomHtmlComponent implements OnInit {
  @Input() set component(value: StrapiCustomHtmlComponent) {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(
      value.htmlContent
    );
  }

  htmlContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
