import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StrapiBook,
  StrapiBookImageDisplayTypeEnum,
  StrapiImageSizeEnum,
} from '@moose/pwn-cms-model/lib';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { tap } from 'rxjs/operators';

import { environment } from '@env/environment';

@Component({
  selector: 'app-promoted-book',
  templateUrl: './promoted-book.component.html',
  styleUrls: [
    './promoted-book.component.scss',
    './promoted-book.component.mobile.scss',
  ],
})
export class PromotedBookComponent implements OnInit, OnDestroy {
  readonly tenantId = environment.tenantId;
  @Input()
  book: StrapiBook;

  @Input()
  bookImageDisplayType: StrapiBookImageDisplayTypeEnum;

  imageUrl: string | undefined;
  public imageDesktopFormat = StrapiImageSizeEnum.thumbnail;
  public imageMobileFormat = StrapiImageSizeEnum.small;
  public isMobile: boolean;
  public resize$: Subscription;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    // Switch template to desktop <-> mobile
    this.resize$ = this.onWindowBreakPoints([
      '(max-width: 1000px)',
    ]).subscribe();
  }

  onWindowBreakPoints(breakPoint: string[]): Observable<BreakpointState> {
    return this.breakpointObserver
      .observe(breakPoint)
      .pipe(tap((res) => (this.isMobile = res.matches)));
  }

  ngOnDestroy(): void {
    this.resize$?.unsubscribe();
  }
}
