<div class="article-author-content-container">
  <img
    *ngIf="author.img?.url"
    alt="{{ author.name }}"
    [imgUrlWithSize]="imageFormat"
    [imgUrl]="author.img.url"
    class="author-img-container"
  />
  <div class="d-flex author-wrapper">
    <div class="author-info-container">
      <span class="author-label">Autor</span>
      <span class="author-name">{{ author.name }}</span>
      <span class="author-bio" [innerHTML]="author.bio"></span>
    </div>
    <div class="button-container">
      <a [routerLink]="['/autor', authorSlug]">
        <span>Zobacz więcej artykułów tego autora</span>
      </a>
    </div>
  </div>
</div>
