<!--<app-toast></app-toast>-->

<div
  *ngIf="title && isBrowser()"
  class="bg-image"
  [style]="{
    background:
      newsletterImage && style !== 'small'
        ? 'url(' +
          newsletterImage.getPublicImageUrl(
            getServerUrl() + '/strapi-proxy/' + tenantId
          ) +
          ')'
        : 'none'
  }"
>
  <div
    class="newsletter"
    [ngClass]="{
      newsletter: style !== 'small',
      newsletter_small: style === 'small'
    }"
  >
    <div
      [ngClass]="{
        'newsletter-text-block': style !== 'small',
        'newsletter-text-block-small': style === 'small'
      }"
      [innerHTML]="title"
    ></div>
    <div
      [ngClass]="{
        'newsletter-text-block': style !== 'small',
        'newsletter-text-block-small': style === 'small'
      }"
      [innerHTML]="description"
    ></div>
    <div class="form-container">
      <form
        data-synerise="newsletter"
        novalidate
        class="email-form"
        [formGroup]="emailForm"
      >
        <div class="field-container">
          <span class="p-float-label">
            <input
              id="email"
              data-synerise="email"
              type="text"
              formControlName="email"
              pInputText
              [ngClass]="{ error: hasErrors(email), dirty: email.dirty }"
            />
            <label
              for="email"
              [ngClass]="{ dirty: email.dirty, error: hasErrors(email) }"
              >Adres e-mail</label
            >
          </span>
          <div class="errors-wrapper">
            <div class="control-errors" *ngIf="hasErrors(email)">
              <p *ngIf="email.errors.required">Pole jest wymagane</p>
              <p *ngIf="email.errors.maxlength">
                Email może mieć maksymalnie 254 znaki
              </p>
              <p *ngIf="email.errors.pattern">Niepoprawny adres email</p>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button
            class="submit-button"
            [disabled]="hasErrors(email) || !email.dirty"
            [ngClass]="{
              'submit-button-enabled': !hasErrors(email) && email.dirty,
              'submit-button-disabled': hasErrors(email) || !email.dirty
            }"
            type="submit"
            (click)="openModal(modal)"
          >
            Wyślij
          </button>
        </div>
      </form>
    </div>
  </div>

  <ng-template #modal let-modal id="modal1">
    <div class="newsletter-modal-content">
      <div class="newsletter-modal-content-top">
        <span class="newsletter-modal-title">Psychologia</span>
        <span class="close-button material-icons" (click)="modal.close()"
          >close</span
        >
      </div>
    </div>
    <div class="newsletter-modal-content-middle" #test>
      <div class="centered">
        <h1>Potwierdź zapis</h1>
        <h2>{{ protectedEmail() }}</h2>
      </div>
      <div class="centered">
        <form
          class="form-container"
          [formGroup]="agreementsForm"
          data-synerise="newsletter"
        >
          <div class="terms" *ngFor="let agreement of mandatoryAgreements">
            <input
              [id]="'agreement-' + agreement.code"
              class="terms-checkbox"
              type="checkbox"
              [value]="agreement.control.value"
              [formControlName]="'agreement-' + agreement.code"
              [ngClass]="{ error: hasErrors(agreement.control) }"
              data-synerise="newsletterAgreement"
              data-synerise-value="enabled"
              required
            />
            <div class="terms-controls">
              <label [for]="'agreement-' + agreement.code">
                <span style="color: red;">*</span> {{ agreement.text }}
              </label>
              <i
                class="far fa-question-circle cursor-pointer"
                (click)="
                  displayToast(
                    'agreement-' + agreement.code,
                    agreement.fullText
                  )
                "
              ></i>
            </div>
            <div
              class="control-errors"
              [ngStyle]="{
                visibility: hasErrors(agreement.control) ? 'inherit' : 'hidden'
              }"
            >
              <p
                *ngIf="agreement?.control?.errors?.required"
                style="color: red;"
              >
                Pole jest wymagane
              </p>
            </div>
          </div>
          <div class="terms" *ngFor="let agreement of optionalAgreements">
            <input
              [id]="'agreement-' + agreement.code"
              class="terms-checkbox"
              type="checkbox"
              [value]="agreement.control.value"
              [formControlName]="'agreement-' + agreement.code"
            />
            <div class="terms-controls">
              <label [for]="'agreement-' + agreement.code">
                {{ agreement.text }}
              </label>
              <i
                class="far fa-question-circle cursor-pointer"
                (click)="
                  displayToast(
                    'agreement-' + agreement.code,
                    agreement.fullText
                  )
                "
              ></i>
            </div>
          </div>
          <div
            *ngIf="authConfig && authConfig.additionalAgreementTextNewsletter"
            class="additional-agreement"
          >
            <div
              class="innerHTML"
              [innerHTML]="authConfig.additionalAgreementTextNewsletter"
            ></div>
          </div>
          <button
            [disabled]="!areMandatoryAgreementsAccepted()"
            class="newsletter-confirm-button"
            [ngClass]="{ 'submit-button-disabled': subscribingToNewsletter }"
            (click)="changeModalPage(modal, modal2)"
            type="submit"
          >
            Potwierdzam
          </button>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #modal2 let-modal2>
    <div class="newsletter-modal-content">
      <div class="newsletter-modal-content-top">
        <span class="newsletter-modal-title">Psychologia</span>
        <span class="close-button material-icons" (click)="modal2.close()"
          >close</span
        >
      </div>
    </div>
    <div class="newsletter-modal-content-middle">
      <div class="centered">
        <h1>Dziękujemy.</h1>
        <h3>
          <ng-container *ngIf="singleOptIn; else elseTemplate">
            Twój adres email został dodany do listy subskrybentów. Od teraz
            będziesz na bieżąco z najnowszymi artykułami oraz zniżkami
            dostępnymi w serwisach Grupy PWN.
          </ng-container>
          <ng-template #elseTemplate>
            Wysłaliśmy na Twój adres email link aktywacyjny. Potwierdź zapis i
            bądź na bieżąco z najnowszymi artykułami.
          </ng-template>
        </h3>
        <button (click)="modal2.close()" class="newsletter-confirm-button">
          Zamknij
        </button>
      </div>
    </div>
  </ng-template>
</div>
