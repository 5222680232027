import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PlatformService extends Platform {
  public static isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<object>) {
    super(platformId);
    PlatformService.isBrowser = isPlatformBrowser(this.platformId);
  }
}
