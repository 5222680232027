import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticlesComponent } from '@app/articles/articles.component';

const routes: Routes = [
  { path: 'artykuly/:categoryId/:tagId', component: ArticlesComponent },
  { path: 'artykuly', component: ArticlesComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ArticlesRoutingModule {}
