import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from '@app/@shared/service/breadcrumb/breadcrumb.service';
import { BreadcrumbArticle } from '@app/@shared/model/breadcrumb/breadcrumb-article';
import { BreadcrumbCategory } from '@app/@shared/model/breadcrumb/breadcrumb-category';
import { BreadcrumbState } from '@app/@shared/model/breadcrumb/breadcrumb-state';
import {
  Article,
  CategoryTreeNode,
  StrapiAuthor,
  StrapiEvent,
  StrapiJobOffer,
  UrlUtils,
} from '@moose/pwn-cms-model/lib';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { BreadcrumbType } from '@app/@shared/model/breadcrumb/breadcrumb-type';
import { BreadcrumbEvent } from '@app/@shared/model/breadcrumb/breadcrumb-event';
import { BreadcrumbAuthor } from '@app/@shared/model/breadcrumb/breadcrumb-author';
import { BreadcrumbJobOffer } from '@shared/model/breadcrumb/breadcrumb-job-offer';
import { BreadcrumbBookList } from '@app/@shared/model/breadcrumb/breadcrump-book-list';
import { BreadcrumbEventList } from '@app/@shared/model/breadcrumb/breadcrump-event-list';
import { BreadcrumbNewestList } from '@app/@shared/model/breadcrumb/breadcrumb-newest-list';
import { BreadcrumbAuthorsList } from '@app/@shared/model/breadcrumb/breadcrump-authors';
import { BreadcrumbAbout } from '@app/@shared/model/breadcrumb/breadcrump-about';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: [
    './breadcrumb.component.scss',
    './breadcrumb.component.mobile.scss',
  ],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  currentState:
    | BreadcrumbState
    | BreadcrumbArticle
    | BreadcrumbCategory
    | BreadcrumbAuthor
    | BreadcrumbJobOffer
    | null = null;

  articleTreeNode: CategoryTreeNode | undefined;

  stateSubscription: Subscription;
  treeSubscription: Subscription;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  BreadcrumbType = BreadcrumbType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UrlUtils = UrlUtils;

  slugIdMap = new Map();

  constructor(
    private breadcrumbService: BreadcrumbService,
    private categoryService: CategoryService
  ) {}

  ngOnInit(): void {
    this.categoryService.getCategories().subscribe((categories) => {
      for (const cat of categories) {
        this.slugIdMap.set(cat.id, UrlUtils.toSlugId(cat.displayName, cat.id));
      }
    });

    this.stateSubscription = this.breadcrumbService.state.subscribe((state) => {
      this.currentState = state;

      if (
        state &&
        state.type === BreadcrumbType.article &&
        this.article.categories
      ) {
        const category = this.article.categories[0];

        this.treeSubscription?.unsubscribe();
        this.treeSubscription = this.categoryService
          .getNavigationTree(category.id)
          .subscribe((categoryTree) => {
            this.articleTreeNode = categoryTree?.findOne(category.id);
          });
      }
    });
  }

  ngOnDestroy() {
    this.stateSubscription?.unsubscribe();
    this.treeSubscription?.unsubscribe();
  }

  get category(): CategoryTreeNode {
    return (this.currentState as BreadcrumbCategory).category;
  }

  get isBooks(): boolean {
    return (this.currentState as BreadcrumbCategory).isBooks;
  }

  get article(): Article {
    return (this.currentState as BreadcrumbArticle).article;
  }

  get author(): StrapiAuthor {
    return (this.currentState as BreadcrumbAuthor).author;
  }

  get event(): StrapiEvent {
    return (this.currentState as BreadcrumbEvent).event;
  }

  get jobOffer(): StrapiJobOffer {
    return (this.currentState as BreadcrumbJobOffer).jobOffer;
  }

  get bookList(): string {
    return (this.currentState as BreadcrumbBookList).books;
  }

  get eventList(): string {
    return (this.currentState as BreadcrumbEventList).calendar;
  }

  get newestList() {
    return (this.currentState as BreadcrumbNewestList).newest;
  }

  get authorList() {
    return (this.currentState as BreadcrumbAuthorsList).authors;
  }

  get aboutS() {
    return (this.currentState as BreadcrumbAbout).about;
  }

  stayOnPage(event: Event) {
    event.preventDefault();
  }
}
