export class Constants {
  public static readonly EMAIL_PATTERN: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly NIP_PATTERN: string = '[0-9]{10}';
  public static readonly LOGIN_DATA_SAVED: string =
    'Dane logowania zostały zapisane';
  public static readonly ADDITIONAL_DATA_SAVED: string =
    'Dane dodatkowe zostały zapisane';
  public static readonly INVOICE_DATA_SAVED: string =
    'Dane do faktury zostały zapisane';
  public static readonly DATA_SAVE_FAILED: string =
    'Aktualizacja danych nie powiodła się, spróbuj później';
}
