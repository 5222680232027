<img
  [style]="{
    width: component.width > 570 ? '570px' : component.width + 'px',
    height: component.height + 'px'
  }"
  [ngClass]="{ 'image-max-width': !component.width }"
  class="main-image"
  (click)="openGallery(galeryModal)"
  [imgUrlWithSize]="imageFormat"
  [imgUrl]="component.images[0].img?.url"
/>

<ng-template #galeryModal let-modal>
  <ngb-carousel
    *ngIf="imagesSrc"
    [interval]="0"
    [showNavigationIndicators]="showIndicators"
  >
    <ng-template ngbSlide *ngFor="let image of imagesSrc">
      <div *ngIf="!showFullImage" class="carousel-wrapper">
        <div class="img-wrapper" (click)="toggleSize()">
          <img [src]="image?.url" alt="{{ image?.title }}" />
        </div>
        <div class="carousel-caption">
          <h5>{{ image?.title }}</h5>
          <p [innerHTML]="image?.description"></p>
        </div>
      </div>

      <div *ngIf="showFullImage" class="full-image">
        <img
          (click)="toggleSize()"
          [src]="image?.url"
          alt="{{ image?.title }}"
        />
        <div class="carousel-caption">
          <h5>{{ image?.title }}</h5>
          <p [innerHTML]="image?.description"></p>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
  <span class="material-icons close-button" (click)="modal.close()">clear</span>
</ng-template>
