import { Component, Input, OnInit } from '@angular/core';
import {
  Article,
  StrapiComponent,
  StrapiComponentTypeEnum,
} from '@moose/pwn-cms-model/lib';

@Component({
  selector: 'app-article-side-content',
  templateUrl: './article-side-content.component.html',
  styleUrls: [
    './article-side-content.component.scss',
    './article-side-content.component.mobile.scss',
  ],
})
export class ArticleSideContentComponent implements OnInit {
  @Input()
  components: StrapiComponent[];

  @Input()
  article: Article;

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  constructor() {}

  ngOnInit(): void {}
}
