import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SyneriseService {
  getNativeWindow(): any {
    return typeof window !== 'undefined' ? window : undefined;
  }
  pageVisit() {
    const nativeWindow = this.getNativeWindow();
    if (
      nativeWindow &&
      nativeWindow.SR &&
      nativeWindow.SR.event &&
      typeof nativeWindow.SR.event.pageVisit === 'function' &&
      nativeWindow.SR.dynamicContent &&
      typeof nativeWindow.SR.dynamicContent.get === 'function'
    ) {
      try {
        nativeWindow.SR.event.pageVisit().then(() => {
          nativeWindow.SR.dynamicContent.get();
        });
      } catch (error) {
        console.log('Synterise pageVisit event error: ', error);
      }
    }
  }
  initFormCatch() {
    const nativeWindow = this.getNativeWindow();
    if (nativeWindow && nativeWindow.SyneriseTC) {
      const synInstance = nativeWindow.SyneriseTC;
      if (synInstance && typeof synInstance.initFormCatch === 'function') {
        synInstance.initFormCatch();
      }
    }
  }
  trackCustomEvent(actionName: string, paramsObject: any, eventLabel: string) {
    const nativeWindow = this.getNativeWindow();
    if (
      nativeWindow &&
      nativeWindow.SR &&
      nativeWindow.SR.event &&
      typeof nativeWindow.SR.event.trackCustomEvent === 'function'
    ) {
      try {
        nativeWindow.SR.event.trackCustomEvent(
          actionName,
          paramsObject,
          eventLabel
        );
      } catch (error) {
        console.log('Synterise trackCustomEvent error: ', error);
      }
    }
  }
}
