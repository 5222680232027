import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MainMenuLink } from '@shared/model/menu/main-menu-link';
import { StrapiComponentTypeEnum } from '@moose/pwn-cms-model/lib';
import { CategoryService } from '@app/@shared/service/category/category.service';
import { Logger } from '@core';

const log = new Logger('MainMenuEntryComponent');

@Component({
  selector: 'app-category-menu-entry',
  templateUrl: './main-menu-entry.component.html',
  styleUrls: [
    './main-menu-entry.component.scss',
    './main-menu-entry.component.small.scss',
  ],
})
export class MainMenuEntryComponent implements OnInit, OnDestroy {
  @Input()
  link: MainMenuLink;

  @Input()
  mainMenuState: Subject<MainMenuLink>;

  selectedLink: MainMenuLink = null;

  // subscriptions
  menuStateSubscription: Subscription;

  componentTypes: typeof StrapiComponentTypeEnum = StrapiComponentTypeEnum;

  slugIdMap = new Map();

  categoriesSub: Subscription;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    log.debug(
      'CategoryMenuEntryComponent INIT: ' +
        this.link.displayName +
        ' ' +
        this.link.targetId
    );
    this.menuStateSubscription = this.mainMenuState.subscribe(
      (link) => (this.selectedLink = link)
    );
    this.categoriesSub = this.categoryService
      .getCategories()
      .subscribe((categories) => {
        categories.forEach((cat) => {
          this.slugIdMap.set(cat.id, this.categoryService.getSlugId(cat));
        });
      });
  }

  ngOnDestroy(): void {
    log.debug(
      'CategoryMenuEntryComponent DESTROY: ' +
        this.link.displayName +
        ' ' +
        this.link.targetId
    );
    this.menuStateSubscription?.unsubscribe();
    this.categoriesSub?.unsubscribe();
  }

  onMenuClicked() {
    if (!this.isSelected()) {
      log.debug(
        'CategoryMenuEntryComponent: sending event with category: ' +
          this.link.displayName
      );
      // this.mainMenuState.next(null);
      // window.setTimeout(f => this.mainMenuState.next(this.link), 100);
      this.mainMenuState.next(this.link);
    }
  }

  isSelected(): boolean {
    return (
      this.selectedLink != null &&
      this.link.targetId === this.selectedLink.targetId
    );
  }
}
