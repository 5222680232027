import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '@core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, provideImgixLoader } from '@angular/common';

import { SharedModule } from '@shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ArticleModule } from '@app/article/article.module';
import { ArticlesModule } from '@app/articles/articles.module';
import { SmallFooterModule } from '@app/shell/small-footer/small-footer.module';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    ArticleModule,
    ArticlesModule,
    SmallFooterModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    provideImgixLoader(
      `${environment.serverUrl}/strapi-proxy/${environment.tenantId}`
    ),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
