import { Component, Input } from '@angular/core';
import {
  Article,
  SimpleCategory,
  StrapiBook,
  StrapiBookTypeEnum,
  StrapiImageClass,
  StrapiSourcesComponent,
  StrapiImageSizeEnum,
  StrapiImage,
} from '@moose/pwn-cms-model/lib';
import { environment } from '@env/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-article-sources',
  templateUrl: './article-sources.component.html',
  styleUrls: [
    './article-sources.component.scss',
    './article-sources.component.mobile.scss',
  ],
})
export class ArticleSourcesComponent {
  readonly tenantId = environment.tenantId;
  @Input()
  component: StrapiSourcesComponent;

  @Input()
  set article(value: Article) {
    this.mainCategory = value?.categories[0];
    value?.sources.forEach((source: StrapiBook) => {
      this.items.push({
        id: source.id,
        image: source.images[0] ? source.images[0] : undefined,
        title: source.title,
        description: source.description
          ? this._sanitizer.bypassSecurityTrustHtml(source.description)
          : '',
        magazineNumber: source.magazineNumber,
        magazineTotalNumber: source.magazineTotalNumber,
        year: source.year,
        linkUrl: source.link,
        customLinkUrl: source.customLink ? source.customLink.trim() : '',
        customLinkName: source.customLinkName
          ? source.customLinkName.trim()
          : '',
        isMagazine: source.type === StrapiBookTypeEnum.magazine,
      });
    });

    this._sourcesExternal = value?.sourcesExternal;

    this.visible = this.items.length > 0 || this._sourcesExternal != null;
  }

  mainCategory: SimpleCategory;

  items: {
    id: string;
    image: StrapiImage;
    title: string;
    description: SafeHtml;
    magazineNumber: number;
    magazineTotalNumber: number;
    year: number;
    linkUrl: string;
    customLinkUrl: string;
    customLinkName: string;
    isMagazine: boolean;
  }[] = [];

  visible = false;
  public imageFormat = StrapiImageSizeEnum.thumbnail;

  private _sourcesExternal: string | undefined = null;

  constructor(private _sanitizer: DomSanitizer) {}

  getSourcesExternalHtml(): SafeHtml | undefined {
    if (this._sourcesExternal != null) {
      return this._sanitizer.bypassSecurityTrustHtml(this._sourcesExternal);
    } else {
      return null;
    }
  }

  getImageAlt(imageObj: StrapiImage, itemTitle: string): string {
    if (imageObj.alternativeText && imageObj.alternativeText.length) {
      return imageObj.alternativeText;
    }
    return itemTitle;
  }
}
