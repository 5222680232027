import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreadcrumbState } from '@app/@shared/model/breadcrumb/breadcrumb-state';
import { BreadcrumbCategory } from '@app/@shared/model/breadcrumb/breadcrumb-category';
import {
  CategoryTreeNode,
  Article,
  StrapiEvent,
  StrapiAuthor,
  StrapiJobOffer,
} from '@moose/pwn-cms-model/lib';
import { BreadcrumbArticle } from '@app/@shared/model/breadcrumb/breadcrumb-article';
import { BreadcrumbEvent } from '@app/@shared/model/breadcrumb/breadcrumb-event';
import { BreadcrumbAuthor } from '@app/@shared/model/breadcrumb/breadcrumb-author';
import { BreadcrumbJobOffer } from '@shared/model/breadcrumb/breadcrumb-job-offer';
import { BreadcrumbEventList } from '@app/@shared/model/breadcrumb/breadcrump-event-list';
import { BreadcrumbBookList } from '@app/@shared/model/breadcrumb/breadcrump-book-list';
import { BreadcrumbNewestList } from '@app/@shared/model/breadcrumb/breadcrumb-newest-list';
import { BreadcrumbAuthorsList } from '@app/@shared/model/breadcrumb/breadcrump-authors';
import { BreadcrumbAbout } from '@app/@shared/model/breadcrumb/breadcrump-about';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _state = new BehaviorSubject<BreadcrumbState | null>(null);

  constructor(private router: Router) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.reset();
      }
    });
  }

  get state(): Observable<BreadcrumbState | null> {
    return this._state;
  }

  reset() {
    this._state.next(null);
  }

  set category(value: CategoryTreeNode) {
    const isBooks = this.router.url.startsWith('/books/');
    this._state.next(new BreadcrumbCategory(value, isBooks));
  }

  set article(value: Article) {
    this._state.next(new BreadcrumbArticle(value));
  }

  set event(value: StrapiEvent) {
    this._state.next(new BreadcrumbEvent(value));
  }

  set author(value: StrapiAuthor) {
    this._state.next(new BreadcrumbAuthor(value));
  }

  set jobOffer(value: StrapiJobOffer) {
    this._state.next(new BreadcrumbJobOffer(value));
  }

  set eventList(value: string) {
    this._state.next(new BreadcrumbEventList(value));
  }

  set newestList(value: string) {
    this._state.next(new BreadcrumbNewestList(value));
  }

  set authorsList(value: string) {
    this._state.next(new BreadcrumbAuthorsList(value));
  }

  set bookList(value: string) {
    this._state.next(new BreadcrumbBookList(value));
  }

  set aboutS(value: string) {
    this._state.next(new BreadcrumbAbout(value));
  }
}
