export enum BreadcrumbType {
  article,
  author,
  category,
  event,
  jobOffer,
  books,
  calendar,
  newest,
  authors,
  about,
}
