import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfigurationService } from '@app/@shared/service/configuration/configuration.service';
import { StrapiImageClass } from '@moose/pwn-cms-model/lib';
import { Subscription } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss', './header.component.mobile.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() text = '';

  configurationSubscription: Subscription | null = null;
  glomerulus: StrapiImageClass | null = null;

  readonly tenantId = environment.tenantId;

  constructor(private readonly configurationService: ConfigurationService) {}

  ngOnInit(): void {
    this.configurationSubscription = this.configurationService
      .getConfiguration()
      .subscribe((config) => {
        if (config != null) {
          this.glomerulus = new StrapiImageClass(config.glomerulusWhite);
        }
      });
  }

  ngOnDestroy(): void {
    this.configurationSubscription?.unsubscribe();
  }

  getServerUrl(): string {
    return environment.serverUrl;
  }
}
