<div class="attachment-container">
  <a
    [href]="attachmentUrl"
    class="attachment-download-container"
    target="_blank"
  >
    <!-- <div class="attachment-download-container" (click)="download(getImageUrl(), component.file.name)"></div> -->
    <div class="icon-and-size-container">
      <div class="attachment-size">
        ({{ (component.file.size / 1024).toFixed(2) }} MB)
      </div>
      <img class="icon" [src]="fileIconUrl" alt="Ikona pliku" loading="lazy" />
    </div>
    <div class="attachment-name">{{ component.name }}</div>
  </a>
  <!-- </div> -->
  <div
    class="attachment-description-desktop"
    [innerHTML]="component.description"
  ></div>
</div>
<div
  class="attachment-description-mobile"
  [innerHTML]="component.description"
></div>
